import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  page: {
    flexGrow: 1,
    padding: '10px 16px'
  },
  resetPasswordButton: {
    width: '100%',
    marginTop: '12px',
    color: '#29333D',
    fontWeight: 500,
    border: '1px solid #E8ECF0',
    borderRadius: '4px'
  }
}))
