import {Box} from '@mui/material'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {DetailsField} from '../../../components/DetailsField/DetailsField'
import {useOrgUnit} from '../../../hooks/useOrgUnit'
import {RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {WidgetUserCreationFormData} from '../../WidgetUserCreation/WidgetUserCreation.form'

interface UserDetailsFieldProps {
  roles?: RoleAssignment[] | null
  isRolesLoading?: boolean
  cscData?: RoleAssignment[]
  formMethods: UseFormReturn<WidgetUserCreationFormData>
}

// eslint-disable-next-line complexity
export const UserDetailsField = ({
  roles,
  isRolesLoading,
  cscData,
  formMethods
}: UserDetailsFieldProps) => {
  const {t} = useTranslation()
  const countriesData = window.intlTelInputGlobals.getCountryData()

  const businessLines = roles
    ?.map((role) => role.dataScope?.['businessLine'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter((value) => !!value)

  const orgUnits = roles
    ?.map((role) => role.dataScope?.['orgUnitId'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter((value) => !!value)
    .filter((value, index, array) => array.indexOf(value) === index)

  const cscCountries = cscData
    ?.map((data) => data.dataScope['countryId'])
    .filter((country, index, countries) => countries.indexOf(country) === index) as
    | string[]
    | undefined

  const selectedBusinessLine = formMethods.watch('businessLine')
  const selectedCountry = formMethods.watch('country')

  const countries = roles
    ?.map((role) => role.dataScope?.['countryId'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter((value) => !!value)
    .filter((value, index, array) => array.indexOf(value) === index)

  const cscBusinessLines = cscData
    ?.map((data) => data.dataScope['businessLine'])
    .filter(
      (businessLine, index, businessLines) => businessLines.indexOf(businessLine) === index
    ) as string[] | undefined

  const {data: orgUnitsData, isLoading: isLoadingOrgUnits} = useOrgUnit(
    countries && countries?.length > 1 ? selectedCountry?.toUpperCase() : countries?.[0] || '',
    cscBusinessLines && cscBusinessLines?.length > 1
      ? selectedBusinessLine?.toUpperCase()
      : cscBusinessLines?.[0] || '',
    false
  )

  if (countries && countries.length > 1) {
    return null
  }

  return (
    <Box>
      {countries && countries.length > 1 && cscCountries && cscCountries?.length > 1 && (
        <Box mt={2}>
          <DetailsField
            label={t('quickPreview.country')}
            value={
              countries && countries.length > 0 && cscCountries && cscCountries?.length > 1
                ? Array.from(
                    new Set(
                      countries &&
                        countries.map(
                          (country) =>
                            countriesData.find(
                              (countryData) =>
                                countryData.iso2.toLowerCase() === country.toLowerCase()
                            )?.name
                        )
                    )
                  )
                    .sort()
                    .join(', ')
                : '-'
            }
            isLoading={isRolesLoading}
            dataTestId="userList-details-country-field"
          />
        </Box>
      )}
      <Box mt={2}>
        <DetailsField
          label={t('quickPreview.businessLine')}
          value={
            businessLines && businessLines.length > 0
              ? Array.from(
                  new Set(
                    businessLines &&
                      businessLines.map((bl) => t(`usersList.businessLine.${bl.toLowerCase()}`))
                  )
                )
                  .sort()
                  .join(', ')
              : '-'
          }
          isLoading={isRolesLoading}
          dataTestId="userList-details-businessLine-field"
        />
      </Box>
      <Box mt={2}>
        <DetailsField
          label="Org Unit"
          value={
            orgUnits && orgUnits.length > 0
              ? Array.from(
                  new Set(
                    orgUnits &&
                      orgUnits.map(
                        (orgUnit) =>
                          orgUnitsData?.find((orgUnitData) => orgUnitData.key === orgUnit)?.label
                      )
                  )
                )
                  .sort()
                  .join(', ')
              : '-'
          }
          isLoading={isRolesLoading || isLoadingOrgUnits}
          loadingStyle={{width: '80px'}}
          dataTestId="userList-details-orgUnits-field"
        />
      </Box>
    </Box>
  )
}
