import {Typography} from '@hconnect/uikit'
import {Avatar, Box, List, ListItem, ListItemAvatar, Skeleton, ListItemText} from '@mui/material'
import clsx from 'clsx'
import React from 'react'

import {useListViewStyles} from './ListView.styles'
import {ListViewPagination} from './ListViewPagination'

export type ListViewItem = {
  id: string
  title: string | React.ReactNode
  subtitle: string | React.ReactNode
  avatar?: React.ReactNode
}

export type ListViewPaginationOptions = {
  count: number
  page: number
  rowsPerPage: number
  onChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void
  onChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

type ListViewProps = {
  items: ListViewItem[]
  isLoading: boolean
  noResultsMessage: string
  onClick?: (item: ListViewItem) => void
  paginationOptions?: ListViewPaginationOptions
}
export const ListView = ({
  items,
  onClick,
  isLoading,
  paginationOptions,
  noResultsMessage
}: ListViewProps) => {
  const {classes} = useListViewStyles()
  if (isLoading) {
    return (
      <List>
        {[0, 1, 2].map((item) => (
          <ListItem
            key={item}
            onClick={() => {}}
            style={{borderBottom: '1px solid #E8ECF0', padding: '8px 0'}}
          >
            <ListItemText primary={<Skeleton width={200} />} secondary={<Skeleton width={100} />} />
          </ListItem>
        ))}
      </List>
    )
  }

  if (items.length === 0) {
    return <Typography style={{fontWeight: 600}}>{noResultsMessage}</Typography>
  }

  return (
    <Box>
      <List style={{marginLeft: '-14px', marginRight: '-14px'}} data-test-id="list-view">
        {items.map((item) => (
          <ListItem
            key={item.id}
            data-test-id={`list-view-item-${item.id}`}
            onClick={() => onClick?.(item)}
            className={clsx({
              [classes.listItem]: true,
              [classes.listItemClickable]: !!onClick
            })}
          >
            {item.avatar && (
              <ListItemAvatar>
                <Avatar>{item.avatar}</Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              style={{color: '#000000', pointerEvents: 'auto'}}
              primary={
                <Typography
                  style={{color: '#000000', fontWeight: 600, fontSize: '16px', lineHeight: '24px'}}
                >
                  {item.title}
                </Typography>
              }
              secondary={
                <Typography style={{color: '#000000', fontSize: '14px'}}>
                  {item.subtitle}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      {paginationOptions && (
        <ListViewPagination
          count={paginationOptions.count}
          page={paginationOptions.page}
          onPageChange={paginationOptions.onChangePage}
          rowsPerPage={paginationOptions.rowsPerPage}
          onRowsPerPageChange={paginationOptions.onChangeRowsPerPage}
        />
      )}
    </Box>
  )
}
