import {Button} from '@mui/material'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {deleteUserRole} from '../../WidgetUserCreation/hooks/useCreateWidgetUser'

const deleteUserRoles = async (roles: RoleAssignment[]) => {
  for (const userRole of roles) {
    await deleteUserRole(userRole)
  }
}
export const useDeleteUserRoles = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(deleteUserRoles, {
    onSuccess: () => {
      void queryClient.invalidateQueries(['roles'])
    },
    onError: (e) => {
      const error = e as AxiosError
      enqueueSnackbar(
        error.response?.status === 304
          ? t('quickPreview.addPermissions.form.errorRoleExistsMsg')
          : (error.response?.data as any)?.message || t('errorboundary.error'),
        {
          anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
          variant: 'error',
          action: (key) => (
            <Button
              style={{color: '#FFFFFF', borderColor: 'transparent'}}
              onClick={() => closeSnackbar(key)}
            >
              Close
            </Button>
          )
        }
      )
    }
  })
}
