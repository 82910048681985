import {Product, User} from '@hconnect/apiclient'
import {ResponsiveTable} from '@hconnect/uikit'
import {Box, Typography} from '@mui/material'
import moment from 'moment'
import React, {useEffect, useState} from 'react'

import {InviteUserRows} from './InviteUserRows'

interface InviteUserProps {
  user?: User
  refetchUser: () => void
}

export const InviteUser = ({user, refetchUser}: InviteUserProps) => {
  const [isHubInvited, setIsHubInvited] = useState(false)

  const [isOnSiteInvited, setIsOnSiteInvited] = useState(false)

  useEffect(() => {
    if (user) {
      setIsHubInvited(
        user?.invitationProducts?.some((product) => product.product === Product.Hub) ?? false
      )
      setIsOnSiteInvited(
        user?.invitationProducts?.some((product) => product.product === Product.OnSite) ?? false
      )
    }
  }, [user])

  const getLastActivityDate = (clientId: Product) => {
    const activity = user?.lastActivityDates?.find((date) => date.clientId === clientId)
    return activity ? moment(activity.lastActivityDate).format('DD/MM/YYYY') : 'N/A'
  }

  return (
    <Box style={{width: '100%', marginTop: '48px'}}>
      <Typography style={{fontWeight: 600, fontSize: '18px', marginBottom: '14px'}}>
        Products
      </Typography>
      <ResponsiveTable
        rowSpacingProps={{padding: '10px 16px'}}
        columns={InviteUserRows({user, refetchUser})}
        rows={[
          {
            product: Product.Hub,
            lastActivityDate: getLastActivityDate(Product.Hub),
            isInvited: isHubInvited
          },
          {
            product: Product.OnSite,
            lastActivityDate: getLastActivityDate(Product.OnSite),
            isInvited: isOnSiteInvited
          }
        ]}
        onSort={() => {}}
        keyField="product"
      />
    </Box>
  )
}
