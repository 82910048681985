import {Feature} from '@hconnect/apiclient'
import {AxiosError} from 'axios'
import {QueryFunction, useQuery} from 'react-query'

import {api} from '../../App.store'
import {RoleAssignment} from '../../modules/ManageUsers.selectors'

const disableCrossCountryFilterFetcher: QueryFunction<Feature[]> = async ({queryKey}) => {
  const [base, query] = queryKey
  try {
    const response = await api.get<Feature[]>(`/${base}?${query}`)
    return response.data
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error('Feature is not applicable!')
    }
    throw new Error('Error while fetching feature')
  }
}

export const useDisableCrossCountryFilter = (
  loggedInUserRoles: RoleAssignment[] | null | undefined,
  isCrossBorderApplicable: boolean
) => {

  return useQuery<Feature[], AxiosError>(
    ['features', 'tag=adminconsole&enabled=true'],
    disableCrossCountryFilterFetcher,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!loggedInUserRoles && isCrossBorderApplicable
    }
  )
}
