import LogoHM from '@hconnect/common/branding/branding/icons/Logo-HM.svg'
import {useFeaturesCheckFetcher} from '@hconnect/common/components/FeaturesCheck'
import {trackEventWithBrowserProps} from '@hconnect/common/logging/Analytics'
import {
  AccountDropdown,
  CommonHeader,
  DefaultFooter,
  NavItem,
  useBreakPoints,
  useElementSize
} from '@hconnect/uikit'
import {Shell} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import {nanoid} from 'nanoid'
import {SnackbarProvider} from 'notistack'
import React, {useEffect, useRef} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation} from 'react-router-dom'

import {api, loginFlow} from './App.store'
import {useStyles} from './App.style'
import {useAdminPilotUser} from './common/hooks/useAdminPilotUser'
import {useUserRoles} from './common/hooks/useUserRoles'
import {AutoLogout} from './components/AutoLogout/AutoLogout'
import {autoLogoutConfig} from './components/AutoLogout/AutoLogoutConfig'
import {HeaderLogo} from './components/HeaderLogo'
import {ErrorInfo} from './ErrorInfo'
import {useHeaderBrandings} from './hooks/useHeaderBrandings'
import {
  selectLoggedInUserPermissions,
  selectLoggedInUserProfile
} from './modules/LoggedInUser.selectors'
import {Announcements} from './pages/Announcements/Announcements'
import {AnnouncementCreate} from './pages/Announcements/Announcements.create'
import {CompanyList} from './pages/CompanyList/CompanyList'
import {Configurations} from './pages/Configurations/Configurations'
import {CreateCompany} from './pages/CreateCompany/CreateCompany'
import {CreateUser} from './pages/CreateUser/CreateUser'
import {FeatureCreate} from './pages/FeaturesList/components/FeatureCreate'
import {FeaturesList} from './pages/FeaturesList/FeaturesList'
import {LegalDocuments} from './pages/LegalDocuments/LegalDocuments'
import {ManageCompany} from './pages/ManageCompany/ManageCompany'
import {ManageUser} from './pages/ManageUser/ManageUser'
import {ManageUserPilot} from './pages/ManageUser/ManageUserPilot'
import {Audiences} from './pages/Messages/Audiences/Audiences'
import {CreateMessage} from './pages/Messages/CreateMessage/CreateMessage'
import {MessagesList} from './pages/Messages/MessagesList/MessagesList'
import {QuickPreview} from './pages/QuickPreview/QuickPreview'
import {UsersList} from './pages/UsersList/UsersList'
import {Version} from './pages/Version'
import {WidgetUserCreation} from './pages/WidgetUserCreation/WidgetUserCreation'
import {WidgetUserList} from './pages/WidgetUserList/WidgetUserList'
import {PrivateRoute} from './PrivateRoute'
import {HConnectConfigurationsLanding} from './pages/Configurations/HConnect/HConnectConfigurationsLanding'
import {OrderChangeConfigurationsList} from './pages/Configurations/HConnect/OrderChange/OrderChangeConfigurationsList'
import {OrderChangeConfigurationCreate} from './pages/Configurations/HConnect/OrderChange/OrderChangeConfigurationCreate'
import {OrderChangeConfigurationEdit} from './pages/Configurations/HConnect/OrderChange/OrderChangeConfigurationEdit'
import {OrderChangeConfigurationCopy} from './pages/Configurations/HConnect/OrderChange/OrderChangeConfigurationCopy'
import {useAllowedHConnectConfigCountries} from './pages/Configurations/HConnect/hooks/useAllowedHConnectConfigCountries'

// eslint-disable-next-line complexity
export const App: React.FC = () => {
  const {t, i18n} = useTranslation()

  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const sessionKeyRef = useRef<null | string>(null)
  const location = useLocation()

  const {data: features} = useFeaturesCheckFetcher(api)

  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const permissions = useSelector(selectLoggedInUserPermissions)

  const {data: roles} = useUserRoles(loggedInUserProfile?.user_id)

  const {branding} = useHeaderBrandings(loggedInUserProfile?.defaultBranding)
  const formattedBranding = branding?.nationalLogoUrl?.includes('Logo-HM-Compact-Negative')
    ? LogoHM
    : branding?.nationalLogoUrl
  const canEditAnnouncements = Boolean(
    features &&
      features.find((feature) => feature.name === 'Announcements' && feature.enabled) &&
      loggedInUserProfile?.hasRoles.some((role) => ['GLOBAL_ADMIN', 'SUPER_ADMIN'].includes(role))
  )

  useEffect(() => {
    const defaultLocale = loggedInUserProfile?.defaultLocale
    if (defaultLocale) void i18n.changeLanguage(defaultLocale)
  }, [i18n, loggedInUserProfile])

  useEffect(() => {
    trackEventWithBrowserProps('adminPageview', {
      product: 'adminconsole',
      date: new Date().toISOString(),
      country: loggedInUserProfile?.country ?? '',
      role: loggedInUserProfile?.hasRoles,
      sessionKey: sessionKeyRef.current
    })
  }, [loggedInUserProfile, location])

  useEffect(() => {
    sessionKeyRef.current = nanoid()
  }, [loggedInUserProfile?.id])

  const canEditConfigurations = permissions.some(
    (permission) => permission.permissionType === 'CHANGE_CONFIGURATIONS'
  )

  const configurableHConnectCountries = useAllowedHConnectConfigCountries()
  const canEditHConnectOrderChangeConfigurations = configurableHConnectCountries.length > 0

  const canOnlySeeUserAdminPilot = useAdminPilotUser()

  const canSeeFeatures = permissions.some(
    (permission) => permission.permissionType === 'VIEW_FEATURES'
  )

  const canEditFeatures =
    permissions.some((permission) => permission.permissionType === 'CHANGE_FEATURES') ||
    !!roles?.find(
      (role) =>
        role.roleType === 'GLOBAL_ADMIN' &&
        (role.dataScope?.['countryId'] === '*' || role.dataScope?.['areaId'] === '*')
    ) ||
    false

  const canSeeMessages = permissions.some(
    (permission) => permission.permissionType === 'MANAGE_MESSAGES'
  )

  const canManageCompanies = permissions.some(
    (permission) => permission.permissionType === 'MANAGE_COMPANIES'
  )

  const routes: NavItem[] = [
    {
      label: t('navigation.users'),
      url: '/users',
      dataTestId: 'nav-link-users'
    }
  ]

  if (canManageCompanies) {
    routes.push({
      label: t('navigation.companies'),
      url: '/companies',
      dataTestId: 'nav-link-companies'
    })
  }
  if (canEditConfigurations && !canEditHConnectOrderChangeConfigurations) {
    routes.push({
      label: t('navigation.configurations.configurations'),
      url: '/configuration',
      dataTestId: 'nav-link-configurations'
    })
  }

  if (canEditConfigurations && canEditHConnectOrderChangeConfigurations) {
    routes.push({
      label: t('navigation.configurations.configurations'),
      url: '/configuration',
      dataTestId: 'nav-link-configurations-nested',
      subItems: [
        {
          label: t('navigation.configurations.configurations'),
          url: '/configuration',
          dataTestId: 'nav-link-configurations'
        },
        {
          label: t('navigation.configurations.hconnect'),
          url: '/configuration/hconnect',
          dataTestId: 'nav-link-configurations-hconnect-nested',
          subItems: [
            {
              label: t('navigation.configurations.orderChange'),
              url: '/configuration/hconnect/order-change',
              dataTestId: 'nav-link-configurations-hconnect-order-change'
            }
          ]
        }
      ]
    })
  }

  if (!canEditConfigurations && canEditHConnectOrderChangeConfigurations) {
    routes.push({
      label: t('navigation.configurations.hconnect'),
      url: '/configuration/hconnect',
      dataTestId: 'nav-link-configurations-hconnect-nested',
      subItems: [
        {
          label: t('navigation.configurations.orderChange'),
          url: '/configuration/hconnect/order-change',
          dataTestId: 'nav-link-configurations-hconnect-order-change'
        }
      ]
    })
  }

  if (canEditAnnouncements) {
    routes.push({
      label: t('navigation.announcements'),
      url: '/announcements',
      dataTestId: 'nav-link-announcements'
    })
  }

  if (canSeeFeatures) {
    routes.push({
      label: t('navigation.features'),
      url: '/features',
      dataTestId: 'nav-link-features'
    })
  }

  if (canSeeMessages) {
    routes.push({
      label: t('navigation.messages'),
      url: '/messages',
      dataTestId: 'nav-link-messages',
      subItems: [
        {label: t('navigation.audiences'), url: '/audiences', dataTestId: 'nav-link-audiences'},
        {label: t('navigation.messages'), url: '/messages', dataTestId: 'nav-link-messages'}
      ]
    })
  }

  const [ref, {width}] = useElementSize()
  const {classes} = useStyles()

  return (
    <Router>
      <ErrorBoundary FallbackComponent={() => <ErrorInfo />}>
        <Switch>
          <Route
            path="/manage/quick-preview/:userId"
            exact
            render={() => (
              <Box className={classes.frameStyle}>
                <SnackbarProvider
                  classes={{
                    containerRoot: classes.root
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                >
                  <Box className={classes.headerLogoContainer}>
                    <HeaderLogo
                      logo={formattedBranding}
                      isCustomerAdmin={true}
                      isQuickPreview={true}
                    />
                  </Box>
                  <QuickPreview />
                </SnackbarProvider>
              </Box>
            )}
          />
          <Route
            path="/widgetUserList"
            exact
            render={() => (
              <Box className={classes.frameStyle}>
                <SnackbarProvider
                  classes={{
                    containerRoot: classes.root
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                >
                  <Box className={classes.headerLogoContainer}>
                    <HeaderLogo
                      logo={formattedBranding}
                      isCustomerAdmin={true}
                      isQuickPreview={true}
                    />
                  </Box>
                  <WidgetUserList />
                </SnackbarProvider>
              </Box>
            )}
          />
          <Route
            path="/widgetUserCreation"
            exact
            render={() => (
              <Box className={classes.frameStyle}>
                <SnackbarProvider
                  classes={{
                    containerRoot: classes.root
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                >
                  <Box className={classes.headerLogoContainer}>
                    <HeaderLogo
                      logo={branding?.nationalLogoUrl}
                      isCustomerAdmin={true}
                      isQuickPreview={true}
                    />
                  </Box>
                  <WidgetUserCreation />
                </SnackbarProvider>
              </Box>
            )}
          />
          <Route
            path="/"
            render={() => (
              <SnackbarProvider
                classes={{
                  containerRoot: classes.root
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
              >
                <Shell
                  compact={false}
                  isResponsive
                  boxed={false}
                  onDark={false}
                  showScrollToTop={false}
                  stickyFooter={true}
                  zIndex={1002}
                  header={
                    <div ref={ref} style={{width: '100%'}}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%'
                        }}
                      >
                        {!isMobile && (
                          <HeaderLogo
                            logo={formattedBranding}
                            isCustomerAdmin={canOnlySeeUserAdminPilot}
                          />
                        )}
                        <Box style={{width: isMobile ? 'auto' : '100%'}}>
                          <CommonHeader
                            appName="HConnect"
                            navItems={routes}
                            disableAppSelect
                            parentWidth={width}
                            keepPointerEvents={true}
                          />
                        </Box>
                        {isMobile && (
                          <HeaderLogo
                            withoutBranding={true}
                            isCustomerAdmin={canOnlySeeUserAdminPilot}
                          />
                        )}
                        <AccountDropdown
                          profile={{
                            name: loggedInUserProfile?.name ?? '',
                            email: loggedInUserProfile?.eMail ?? ''
                          }}
                          userPage={
                            location.pathname.includes('useradmin') || !canOnlySeeUserAdminPilot
                              ? `/manage/${loggedInUserProfile?.id}`
                              : undefined
                          }
                          logoutButtonText={t('actionBar.logout')}
                          actions={{
                            logout: () => {
                              void loginFlow.startLogoutProcess()
                            },
                            selectLanguage: () => {}
                          }}
                        />
                      </Box>
                    </div>
                  }
                  footer={<DefaultFooter style={{minHeight: isMobile ? '48px' : '64px'}} />}
                >
                  <Route path="/users" exact render={() => <UsersList />} />
                  <Route path="/useradmin" exact render={() => <UsersList />} />
                  <Route path="/createUser" exact render={() => <CreateUser />} />
                  <Route path="/manage/:userId" exact render={() => <ManageUser />} />
                  <Route path="/managePilot/:userId" exact render={() => <ManageUserPilot />} />
                  <Route path="/companies" exact render={() => <CompanyList />} />
                  <Route path="/companies/create" exact render={() => <CreateCompany />} />
                  <Route
                    path="/companies/manage/:companyId"
                    exact
                    render={() => <ManageCompany />}
                  />
                  <PrivateRoute
                    path="/announcements"
                    exact
                    allowed={canEditAnnouncements}
                    render={() => <Announcements />}
                  />
                  <PrivateRoute
                    allowed={canEditAnnouncements}
                    path="/announcements/create"
                    exact
                    render={() => <AnnouncementCreate />}
                  />
                  <PrivateRoute
                    allowed={canEditAnnouncements}
                    path="/announcements/manage/:messageId"
                    exact
                    render={() => <AnnouncementCreate />}
                  />
                  <PrivateRoute
                    path="/configuration"
                    exact
                    allowed={canEditConfigurations}
                    render={() => <Configurations />}
                  />
                  <PrivateRoute
                    path="/configuration/hconnect"
                    exact
                    allowed={canEditHConnectOrderChangeConfigurations}
                    render={() => <HConnectConfigurationsLanding />}
                  />
                  <PrivateRoute
                    path="/configuration/hconnect/order-change"
                    exact
                    allowed={canEditHConnectOrderChangeConfigurations}
                    render={() => <OrderChangeConfigurationsList />}
                  />
                  <Switch>
                    <PrivateRoute
                      path="/configuration/hconnect/order-change/new"
                      exact
                      allowed={canEditHConnectOrderChangeConfigurations}
                      render={() => <OrderChangeConfigurationCreate />}
                    />
                    <PrivateRoute
                      path="/configuration/hconnect/order-change/:configId/edit"
                      exact
                      allowed={canEditHConnectOrderChangeConfigurations}
                      render={() => <OrderChangeConfigurationEdit />}
                    />
                    <PrivateRoute
                      path="/configuration/hconnect/order-change/:configId/copy"
                      exact
                      allowed={canEditHConnectOrderChangeConfigurations}
                      render={() => <OrderChangeConfigurationCopy />}
                    />
                  </Switch>
                  <PrivateRoute
                    path="/features"
                    exact
                    allowed={canSeeFeatures}
                    render={() => <FeaturesList />}
                  />
                  <PrivateRoute
                    allowed={canEditFeatures}
                    path="/features/create"
                    exact
                    render={() => <FeatureCreate />}
                  />
                  <PrivateRoute
                    allowed={canEditFeatures}
                    path="/features/edit/:featureName"
                    exact
                    render={() => <FeatureCreate />}
                  />
                  <PrivateRoute
                    path="/audiences"
                    exact
                    allowed={canSeeMessages}
                    render={() => <Audiences />}
                  />
                  <PrivateRoute
                    path="/messages/create"
                    exact
                    allowed={canSeeMessages}
                    render={() => <CreateMessage />}
                  />
                  <PrivateRoute
                    path="/messages/details/:messageId"
                    exact
                    allowed={canSeeMessages}
                    render={() => <CreateMessage isReadonly={true} />}
                  />
                  <PrivateRoute
                    path="/messages"
                    exact
                    allowed={canSeeMessages}
                    render={() => <MessagesList />}
                  />
                  <Route path="/legal" exact render={() => <LegalDocuments />} />
                  <Route path="/version" exact render={() => <Version />} />
                  <Route path="/" exact render={() => <Redirect to="/users" />} />
                </Shell>
              </SnackbarProvider>
            )}
          />
        </Switch>
      </ErrorBoundary>
      {loggedInUserProfile?.hasRoles.some((role) => autoLogoutConfig.roles.includes(role)) && (
        <AutoLogout
          onLogout={() => {
            void loginFlow.startLogoutProcess()
          }}
        />
      )}
    </Router>
  )
}
