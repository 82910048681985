import {User} from '@hconnect/apiclient'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../App.store'

type QueryKey = [string, {userId: string; includeCrossBorderUser?: boolean}]

const userFetcher = async ({queryKey}) => {
  const [base, {userId, includeCrossBorderUser}] = queryKey as QueryKey

  if (!userId) return

  const url = `/${base}/${userId}`

  if (includeCrossBorderUser) {
    const params = new URLSearchParams()
    params.append('includeCrossBorderUser', 'true')
    const response = await api.get<User>(`${url}?${params.toString()}`)
    return response.data
  }

  const response = await api.get<User>(url)
  return response.data
}

export const useUser = (userId: string | null, includeCrossBorderUser?: boolean) =>
  useQuery(['users', {userId, includeCrossBorderUser}], userFetcher, {
    refetchOnWindowFocus: true,
    retry: false,
    onError: (error) => {
      console.error(error)
      trackEvent('adminConsoleError', {
        product: 'adminConsole',
        date: new Date().toISOString(),
        errorCode: (error as AxiosError).response?.status,
        component: 'useUser',
        endpoint: `/users/${userId}`
      })
    }
  })
