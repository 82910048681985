import {Item} from '@hconnect/common/Invite/Create/components/LookupDropdown'
import {ErrorPaper, SelectDropdown} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'

import {WidgetInputsProps} from './WidgetInputs.types'

type WidgetCountryInputProps = WidgetInputsProps & {
  countryCodes: string[]
}
export const WidgetCountryInput = ({formMethods, countryCodes}: WidgetCountryInputProps) => {
  const {t} = useTranslation()
  const {classes} = useWidgetUserCreationStyles()
  const countriesData = window.intlTelInputGlobals.getCountryData()
  return (
    <Controller
      name="country"
      control={formMethods.control}
      rules={{
        required: {
          value: true,
          message: t('widgetUserCreation.form.errors.required', {
            fieldName: t('widgetUserCreation.form.country')
          })
        }
      }}
      render={({field}) => (
        <SelectDropdown
          {...field}
          showSearch={false}
          data-test-id="country-dropdown"
          label={t('widgetUserCreation.form.country')}
          className={classes.selectDropdown}
          errorRender={() => <ErrorPaper variant="primaryDark" />}
          selectedProps={{style: {fontWeight: 400}}}
          showError={false}
          options={countriesData
            .filter((data) => countryCodes.includes(data.iso2?.toUpperCase()))
            .map((data) => ({label: data.name, value: data.iso2?.toUpperCase()}))}
          renderItem={(item: Item) => (
            <Box style={{display: 'flex', flexDirection: 'column'}}>
              <Box>{item.label}</Box>
              <Box>{item.value}</Box>
            </Box>
          )}
          stringifyItem={(item: Item) => item.value}
          renderChosenItem={(item: Item) => item.label}
          selectedItem={{
            label:
              countriesData?.find(
                (option) => option.iso2?.toUpperCase() === field.value?.toUpperCase()
              )?.name || '',
            value:
              countriesData?.find(
                (option) => option.iso2?.toUpperCase() === field.value?.toUpperCase()
              )?.iso2 || ' '
          }}
          popoverStyle={{left: 0}}
          onChange={(item) => {
            field.onChange(item.value)
          }}
        />
      )}
    />
  )
}
