import {useBreakPoints} from '@hconnect/uikit'
import CloseIcon from '@mui/icons-material/Close'
import {LoadingButton} from '@mui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material'
import {useMutation} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {api} from '../../../App.store'
import {CloneUser} from '../../../components/UserInformation/CloneUser/CloneUser'
import {RoleAssignment} from '../../../modules/ManageUsers.selectors'

import {useStyles} from './CloneUserDialog.styles'

type CloneUserResponse = {
  userName: string
  password: string
}

type CloneUserDialogProps = {
  showCloneUserDialog: boolean
  setShowCloneUserDialog: (show: boolean) => void
  roles: RoleAssignment[]
  clonableRoleIds: number[]
  userId: string
}
export const CloneUserDialog = ({
  showCloneUserDialog,
  setShowCloneUserDialog,
  roles,
  clonableRoleIds,
  userId
}: CloneUserDialogProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)

  const [selectedRoles, setSelectedRoles] = useState<readonly number[]>([])
  const cloneUser = async (args: Record<string, unknown>): Promise<CloneUserResponse> => {
    const response: AxiosResponse<CloneUserResponse> = await api.post(
      `users/clone/${args.userId}`,
      selectedRoles
    )
    return response.data
  }
  const {data, mutate, isLoading} = useMutation(cloneUser)

  const cloneSuccessNotification = (
    <Box>
      <p>{t('manageUser.cloneSuccess')}</p>
      <p>
        {t('manageUser.userName')}: {data?.userName}
      </p>
      <p>
        {t('manageUser.password')}: {data?.password}
      </p>
    </Box>
  )

  useEffect(() => {
    if (data) {
      enqueueSnackbar(cloneSuccessNotification, {
        action: (
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            data-test-id="button-clone-user-close-notification"
            onClick={() => closeSnackbar()}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
        variant: 'success',
        persist: true
      })
      setShowCloneUserDialog(false)
    }
  }, [data])

  return (
    <Dialog
      data-test-id="manage-user-dialog-clone-user"
      open={showCloneUserDialog}
      onClose={() => setShowCloneUserDialog(false)}
      classes={{root: classes.root, paper: classes.paper}}
    >
      <DialogTitle>{t('manageUser.cloneDialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContentText}>
          {t('manageUser.cloneDialog.description')}
        </DialogContentText>
        {isMobile && (
          <Box>
            <CloneUser
              cloneableRoleIds={clonableRoleIds}
              roles={roles}
              setSelectedRolesId={setSelectedRoles}
            />
          </Box>
        )}
        {!isMobile && (
          <CloneUser
            cloneableRoleIds={clonableRoleIds}
            roles={roles}
            setSelectedRolesId={setSelectedRoles}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => setShowCloneUserDialog(false)}
          variant="text"
          style={{color: '#016AD4'}}
          data-test-id="clone-user-dialog-action-cancel"
        >
          {t('manageUser.cloneDialog.cancel')}
        </Button>
        <LoadingButton
          onClick={() =>
            mutate({
              userId,
              roleIdsToClone: selectedRoles
            })
          }
          variant="contained"
          style={{background: '#016AD4'}}
          classes={{loadingIndicator: classes.loadingIndicator, disabled: classes.confirmDisabled}}
          data-test-id="clone-user-dialog-action-confirm"
          loading={isLoading}
          disabled={selectedRoles.length === 0}
        >
          {t('manageUser.cloneDialog.confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
