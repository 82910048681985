import {User} from '@hconnect/apiclient'

import {AppState} from '../App.reducer'
import {Permission} from './LoggedInUser'

export const selectLoggedInUserProfile = (state: AppState): User | null =>
  state.loggedInUser.profile

export const selectLoggedInUserPermissions = (state: AppState): Permission[] =>
  state.loggedInUser.permissions
