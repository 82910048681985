import {User} from '@hconnect/apiclient'
import React from 'react'
import {UseFormReturn} from 'react-hook-form'
import {useSelector} from 'react-redux'

import {UserEmailInput} from '../../../components/UserInformation/inputs/UserEmailInput'
import {UserMobileNumberInput} from '../../../components/UserInformation/inputs/UserMobileNumberInput'
import {UserNameInput} from '../../../components/UserInformation/inputs/UserNameInput'
import {UserInformationFormData} from '../../../components/UserInformation/UserInformationForm.types'
import {
  selectLoggedInUserPermissions,
  selectLoggedInUserProfile
} from '../../../modules/LoggedInUser.selectors'
import {WidgetUserCreationFormData} from '../../WidgetUserCreation/WidgetUserCreation.form'

interface UserFormProps {
  user: User
  formMethods: UseFormReturn<WidgetUserCreationFormData, object>
  handleUpdateUser: (data: UserInformationFormData) => void
  isUserLoading: boolean
}

export const UserForm = ({user, formMethods, isUserLoading}: UserFormProps) => {
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)

  const permissions = useSelector(selectLoggedInUserPermissions)

  const canEditUserEmailPhone =
    loggedInUserProfile?.id === user?.id ||
    permissions.some((permission) => permission.permissionType === 'CHANGE_USER_LOGIN')

  return (
    <>
      <UserNameInput
        formMethods={formMethods}
        name="name"
        isLoading={isUserLoading}
        isQuickPreview={true}
      />
      <UserEmailInput
        formMethods={formMethods}
        canEditUserEmailPhone={canEditUserEmailPhone}
        isLoading={isUserLoading}
        setShowMfaInput={() => {}}
        isQuickPreview={true}
        name="email"
      />
      <UserMobileNumberInput
        formMethods={formMethods}
        canEditUserEmailPhone={canEditUserEmailPhone}
        isLoading={isUserLoading}
        userMobileNumber={user?.mobileNumber}
        isQuickPreview={true}
        name="mobileNumber"
      />
    </>
  )
}
