import {createSelector} from 'reselect'

import {AppState} from '../../../../App.reducer'
import {useAppSelector} from '../../../../hooks/useRedux'

// TODO: add unit tests
const selectHConnectConfigurableCountries = createSelector(
  (state: AppState) => state.loggedInUser.permissions,
  (permissions) =>
    Array.from(
      new Set(
        permissions
          .filter((permission) => permission.permissionType === 'MANAGE_HCONNECT_CONFIGURATIONS')
          .map((permission) => permission.dataScope.countryId)
          .filter((c): c is string => typeof c === 'string')
      )
    )
)

export const useAllowedHConnectConfigCountries = () => {
  const countryIds = useAppSelector(selectHConnectConfigurableCountries)

  return countryIds
}
