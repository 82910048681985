import {
  CustomerToggleBasedPermissionKeys,
  CustomerToggleBasedPermissions
} from '../WidgetUserCreation.types'

enum CustomerRoleEnum {
  FINISHER = 'FINISHER',
  ORDER_PLACER = 'ORDER_PLACER',
  ACCOUNTANT = 'ACCOUNTANT'
}

export const customerRoleSetup = (permissions: CustomerToggleBasedPermissions): string[] => {
  if (
    permissions[CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders] &&
    !permissions[CustomerToggleBasedPermissionKeys.canSeeInvoices]
  ) {
    return [CustomerRoleEnum.ORDER_PLACER]
  }

  if (
    !permissions[CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders] &&
    permissions[CustomerToggleBasedPermissionKeys.canSeeInvoices]
  ) {
    return [CustomerRoleEnum.ACCOUNTANT]
  }

  if (
    permissions[CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders] &&
    permissions[CustomerToggleBasedPermissionKeys.canSeeInvoices]
  ) {
    return [CustomerRoleEnum.ACCOUNTANT, CustomerRoleEnum.ORDER_PLACER]
  }

  return [CustomerRoleEnum.FINISHER]
}
