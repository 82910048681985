import {Content, Page, TitleNav, Typography, useBreakPoints} from '@hconnect/uikit'
import {InputMessage} from '@hconnect/uikit/src/lib2'
import {Close, ArrowBackIos, PeopleAlt, Send} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Tooltip
} from '@mui/material'
import {isEmpty} from 'lodash'
import moment from 'moment'
import React, {CSSProperties, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {SegmentedMessage} from 'sms-segments-calculator'

import {useUser} from '../../../common/hooks/useUser'
import {DetailsField} from '../../../components/DetailsField/DetailsField'
import {SectionHeader} from '../../../components/SectionHeader/SectionHeader'
import {selectLoggedInUserProfile} from '../../../modules/LoggedInUser.selectors'
import {DiscardDialog} from '../../Announcements/components/dialogs/DiscardDialog'
import {useAudience} from '../../UsersList/hooks/useAudience'
import {RTEInput} from '../components/RTEInput'
import {SendMessageConfirmationDialog} from '../dialogs/SendMessageConfirmationDialog'
import {useAttachments} from '../MessagesList/hooks/useAttachments'
import {useMessageDetails} from '../MessagesList/hooks/useMessageDetails'

import {CreateMessageForm, CreateMessageFormData} from './CreateMessage.form'
import {useStyles} from './CreateMessage.styles'
import {useCalculateAudienceSms} from './hooks/useCalculateAudienceSms'
import {CreateMessageParams, useCreateMessage} from './hooks/useCreateMessage'

type CreateMessageProps = {
  isReadonly?: boolean
}

// eslint-disable-next-line complexity
export const CreateMessage = ({isReadonly}: CreateMessageProps) => {
  const screenSizes = useBreakPoints()
  const isSmallMobile = ['xs'].includes(screenSizes)
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const isTablet = ['xs', 'sm', 'md'].includes(screenSizes)
  const {t} = useTranslation()
  const history = useHistory()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const {data: userData, isLoading: isLoadingUserData} = useUser(loggedInUserProfile?.user_id || '')
  const {messageId} = useParams<{messageId: string}>()

  const location = useLocation<
    | {
        selectedAudiencesIds?: number[]
        subject?: string
        messageContent?: string
        redirect: 'audiences' | 'messages' | 'users'
      }
    | undefined
  >()

  const [selectedAudiencesIds, setSelectedAudiencesIds] = useState<number[]>(
    location.state?.selectedAudiencesIds || []
  )
  const [messageInfo, setMessageInfo] = useState<SegmentedMessage>()
  const {data: messageDetails, isInitialLoading: isLoadingMessageDetails} = useMessageDetails(
    parseInt(messageId),
    !!isReadonly
  )
  const {totalPrice, mutateSmsPrice, isLoadingSmsPrice, phoneNumberRecipients, isSmsPriceError} =
    useCalculateAudienceSms(messageInfo?.segmentsCount || 0, messageInfo?.numberOfCharacters || 0)

  const [showConfirmationDialog, setShowConfirmationDialog] = useState<CreateMessageFormData>()

  const {createMessage, createTestMessage, isCreateMessageLoading} =
    useCreateMessage(setShowConfirmationDialog)

  const [attachmentId, setAttachmentId] = useState<number | undefined>()

  const {isFetching: isLoadingAttachment, data: attachmentData} = useAttachments(attachmentId)

  const {classes} = useStyles()

  const defaultCreateMessageForm = {
    subject: location.state?.subject || '',
    channelType: 'Email' as const,
    message: location.state?.messageContent || ''
  }

  const [shouldRedirect, setShouldRedirect] = useState(false)
  const [shouldOpenDiscardDialog, setShouldOpenDiscardDialog] = useState(false)
  const [showSummary, setShowSummary] = useState(false)
  const [isTestMessage, setIsTestMessage] = useState(false)
  const {totalNumberOfRecipients, fetchingNumberOfRecipents, audiencesNames} = useAudience(
    undefined,
    undefined,
    undefined,
    selectedAudiencesIds
  )
  const [showRecipients, setShowRecipients] = useState(false)
  const [files, setFiles] = useState<File[]>([])

  const formMethods = useForm<CreateMessageFormData>({
    defaultValues: defaultCreateMessageForm,
    mode: 'onChange'
  })

  useEffect(() => {
    if (attachmentData) {
      setAttachmentId(undefined)
    }
  }, [attachmentData])

  useEffect(() => {
    if (messageDetails) {
      formMethods.reset({
        subject: messageDetails?.subject,
        channelType: messageDetails?.type === 'email' ? 'Email' : 'sms',
        message: messageDetails?.body
      })
      if (messageDetails?.type === 'sms') {
        setMessageInfo(new SegmentedMessage(messageDetails?.body))
      }
    }
  }, [messageDetails])

  useEffect(() => {
    if (!showConfirmationDialog) {
      setIsTestMessage(false)
    }
  }, [showConfirmationDialog])

  const channelType = formMethods.watch('channelType')

  useEffect(() => {
    formMethods.clearErrors('attachments')
    const totalFilesSize = files
      .map(
        (attachment) =>
          attachment?.size &&
          Math.round((attachment?.size / (1024 * 1024) + Number.EPSILON) * 100) / 100
      )
      .reduce((a, b) => {
        if (a !== undefined && b !== undefined) {
          return a + b
        }
        return 0
      }, 0)

    if (totalFilesSize > 10) {
      formMethods.setError('attachments', {message: 'Total files size is too big', type: 'size'})
    } else {
      formMethods.setValue('attachments', files)
    }
  }, [files])

  const handleShowSummary = (showSum: boolean) => {
    setShowSummary(showSum)
  }

  useEffect(() => {
    if (
      (selectedAudiencesIds.length > 0 ||
        (messageDetails?.audiences &&
          messageDetails?.audiences.map((audience) => audience.id).length > 0)) &&
      channelType === 'sms'
    ) {
      mutateSmsPrice(
        messageDetails?.audiences.map((audience) => audience.id) || selectedAudiencesIds
      )
    }
  }, [selectedAudiencesIds, channelType, messageDetails])

  useEffect(() => {
    if (shouldRedirect) {
      history.push('/audiences')
    }
  }, [shouldRedirect])

  const handleSendMessage = (data: CreateMessageFormData) => {
    const params: CreateMessageParams = {
      subject: data.subject,
      type: data.channelType,
      body: data.message,
      audienceIds: selectedAudiencesIds,
      attachments: channelType === 'Email' ? files : []
    }
    isTestMessage ? createTestMessage(params) : createMessage(params)
  }

  const handleSubmit = (data: CreateMessageFormData) => {
    setShowConfirmationDialog(data)
  }

  const CloseButton = ({onClose, disabled = false}) => {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center" height={80}>
        <div>
          <IconButton
            onClick={() => onClose()}
            style={{border: 'none', boxShadow: 'none'}}
            data-test-id="modal-close-button"
            disabled={disabled}
          >
            <Close fontSize="medium" />
          </IconButton>
        </div>
      </Box>
    )
  }

  const summaryStyle: CSSProperties = isMobile
    ? {
        background: '#FFFFFF',
        color: '#000000',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: '85vh',
        overflowY: 'scroll',
        zIndex: 1001
      }
    : {
        position: !isTablet ? 'static' : 'absolute',
        right: 0,
        background: '#FFFFFF',
        borderLeft: !isTablet ? 'none' : '1px solid #E8E8E8',
        width: '50%',
        zIndex: 1001,
        height: !isTablet ? 'auto' : '100%',
        boxShadow: '0 3px 8px rgba(0, 0, 0, 0.05)',
        overflowY: 'scroll'
      }

  const messageContent = formMethods.watch('message')

  const summarySection = (
    <Grid item lg={4} style={{height: isMobile ? '90%' : undefined}}>
      <Paper elevation={isTablet ? 0 : 4} style={{height: '100%'}}>
        <Box data-test-id="form-right" style={{padding: '24px 36px', height: '100%'}}>
          <Box data-test-id="form-summary" mb={1} style={{height: '100%'}}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                justifyContent: 'space-between',
                height: '100%'
              }}
            >
              <Box style={{display: 'flex', flexDirection: 'column', gap: '24px', height: '100%'}}>
                <SectionHeader title={t('features.form.summary')} />
                {!isReadonly && (
                  <DetailsField
                    label={t('createMessage.summary.messageContent')}
                    dataTestId={'create-message-summary-message'}
                  >
                    <Box style={{overflowY: 'auto', maxHeight: '300px'}}>
                      {channelType === 'Email' ? (
                        <RTEInput readOnly={true} value={messageContent || '-'} />
                      ) : (
                        messageContent || '-'
                      )}
                    </Box>
                  </DetailsField>
                )}
                {channelType === 'Email' && (
                  <DetailsField
                    label={t('createMessage.summary.attachments')}
                    dataTestId="create-message-summary-attachments"
                  >
                    {isReadonly && messageDetails && (
                      <Box style={{display: 'flex', flexDirection: 'column'}}>
                        {messageDetails?.attachments?.length
                          ? messageDetails.attachments?.map((attachment) =>
                              isLoadingAttachment && attachment.attachmentId === attachmentId ? (
                                <Skeleton key={attachment.attachmentId} width={80} height={30} />
                              ) : (
                                <Link
                                  key={attachment.attachmentId}
                                  onClick={() => setAttachmentId(attachment.attachmentId)}
                                >
                                  {attachment.fileName}
                                </Link>
                              )
                            )
                          : '-'}
                      </Box>
                    )}
                    {!isReadonly && files.length > 0 ? (
                      <Box style={{display: 'flex', flexDirection: 'column'}}>
                        {files.map((file) => {
                          return (
                            <Box
                              key={file.name}
                              style={{display: 'flex', justifyContent: 'space-between'}}
                            >
                              <Typography
                                variant="body2"
                                color="textPrimary"
                                style={{wordBreak: 'break-word'}}
                              >
                                {file.name}
                              </Typography>
                              <Typography variant="body2" color="textPrimary">
                                {(
                                  Math.round((file.size / (1024 * 1024) + Number.EPSILON) * 100) /
                                  100
                                ).toFixed(2)}
                                MB
                              </Typography>
                            </Box>
                          )
                        })}
                        <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                          <Typography variant="body2" color="textPrimary">
                            Sum
                          </Typography>
                          <Typography variant="body2" color="textPrimary">
                            {files
                              .map(
                                (attachment) =>
                                  attachment?.size &&
                                  Math.round(
                                    (attachment?.size / (1024 * 1024) + Number.EPSILON) * 100
                                  ) / 100
                              )
                              .reduce((a, b) => {
                                if (a !== undefined && b !== undefined) {
                                  return a + b
                                }
                                return 0
                              }, 0)
                              .toFixed(2)}
                            MB
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      !isReadonly && '-'
                    )}
                  </DetailsField>
                )}
                <DetailsField
                  label={t('createMessage.summary.selectedAudiences')}
                  isLoading={fetchingNumberOfRecipents || isLoadingMessageDetails}
                  dataTestId={'create-message-summary-selectedAudiences'}
                  loadingStyle={{width: '120px', height: '24px'}}
                >
                  {isReadonly
                    ? messageDetails?.recipientsNames.join(', ')
                    : audiencesNames.join(', ') || '-'}
                </DetailsField>
                <DetailsField
                  label={t('createMessage.summary.numberOfRecipients')}
                  dataTestId={'create-message-summary-numberOfRecipients'}
                  isLoading={
                    fetchingNumberOfRecipents || isLoadingSmsPrice || isLoadingMessageDetails
                  }
                  loadingStyle={{width: '40px', height: '24px'}}
                >
                  <Box style={{display: 'flex', flexDirection: 'column'}}>
                    {isReadonly ? messageDetails?.recipientCount : totalNumberOfRecipients || 0}
                    {channelType === 'sms' &&
                      !!totalNumberOfRecipients &&
                      totalNumberOfRecipients !== phoneNumberRecipients &&
                      phoneNumberRecipients > 0 &&
                      totalNumberOfRecipients > 0 && (
                        <InputMessage
                          message={t('createMessage.summary.numberOfRecipientsInfo', {
                            phoneNumberRecipients
                          })}
                          variant="info"
                        />
                      )}
                  </Box>
                </DetailsField>

                {channelType === 'sms' && (
                  <DetailsField
                    label={t('createMessage.summary.totalCost')}
                    dataTestId={'create-message-summary-totalCost'}
                    isLoading={
                      fetchingNumberOfRecipents || isLoadingSmsPrice || isLoadingMessageDetails
                    }
                    loadingStyle={{width: '40px', height: '24px'}}
                    valueStyle={{
                      fontWeight: isSmsPriceError ? 400 : 600,
                      fontSize: isSmsPriceError ? '16px' : '20px',
                      color: isSmsPriceError ? 'red' : undefined
                    }}
                  >
                    {isSmsPriceError
                      ? t('createMessage.summary.totalCostError')
                      : `$ ${totalPrice || 0}`}
                  </DetailsField>
                )}

                {messageDetails && (
                  <DetailsField
                    label={t('messages.details.sent')}
                    dataTestId="message-details-sent-field"
                  >
                    {moment(messageDetails.sentDate).format('DD/MM/YYYY | HH:MM') + 'h'}
                  </DetailsField>
                )}
              </Box>
              <Box style={{visibility: isReadonly ? 'hidden' : 'visible'}}>
                <Tooltip
                  placement="top"
                  title={
                    phoneNumberRecipients === 0 && channelType === 'sms'
                      ? t('createMessage.summary.noPhoneNumberRecipients')
                      : !totalNumberOfRecipients
                        ? t('createMessage.summary.noRecipients')
                        : undefined
                  }
                >
                  <span>
                    <Button
                      data-test-id="summary-action-field-button"
                      type="submit"
                      variant="contained"
                      disabled={
                        isCreateMessageLoading ||
                        (channelType === 'Email'
                          ? formMethods.watch('message') && formMethods.watch('subject')
                            ? selectedAudiencesIds.length === 0 ||
                              totalNumberOfRecipients === 0 ||
                              !isEmpty(formMethods.formState.errors)
                            : !isEmpty(formMethods.formState.errors) ||
                              isEmpty(formMethods.formState.touchedFields) ||
                              !formMethods.formState.dirtyFields.message ||
                              !formMethods.formState.dirtyFields.subject ||
                              selectedAudiencesIds.length === 0 ||
                              totalNumberOfRecipients === 0
                          : formMethods.watch('message')
                            ? selectedAudiencesIds.length === 0 || phoneNumberRecipients === 0
                            : !!formMethods.formState.errors.message ||
                              formMethods.formState.touchedFields.message ||
                              !formMethods.formState.dirtyFields.message ||
                              selectedAudiencesIds.length === 0 ||
                              phoneNumberRecipients === 0 ||
                              isSmsPriceError)
                      }
                      classes={{root: classes.saveButton, disabled: classes.saveButtonDisabled}}
                    >
                      {t('createMessage.form.submit')}
                    </Button>
                  </span>
                </Tooltip>
                <Button
                  data-test-id="summary-action-discard-field-button"
                  variant="outlined"
                  disabled={!formMethods.formState.isDirty}
                  classes={{root: classes.discardButton, disabled: classes.discardButtonDisabled}}
                  onClick={() => setShouldOpenDiscardDialog(true)}
                >
                  {t('createMessage.form.discard')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )

  return (
    <Content>
      <Page
        px={isMobile ? 2 : 6}
        boxed={false}
        className={classes.pageContent}
        title={
          !isTablet && (
            <Box className={classes.pageTitle}>
              {isReadonly ? t('createMessage.detailsTitle') : t('createMessage.title')}
            </Box>
          )
        }
        aboveTitle={
          <Box className={classes.backButton}>
            <TitleNav
              title={
                isTablet
                  ? t('createMessage.title')
                  : t(
                      `createMessage.${
                        location.state?.redirect === 'audiences'
                          ? 'goBackAudiences'
                          : location.state?.redirect === 'users'
                            ? 'goBackUsers'
                            : 'goBackMessages'
                      }`
                    )
              }
              customTitle={
                !isTablet && (
                  <Box className={classes.pageNav}>
                    <ArrowBackIos />
                    <Typography>
                      {t(
                        `createMessage.${
                          location.state?.redirect === 'audiences'
                            ? 'goBackAudiences'
                            : location.state?.redirect === 'users'
                              ? 'goBackUsers'
                              : 'goBackMessages'
                        }`
                      )}
                    </Typography>
                  </Box>
                )
              }
              onClick={() => history.goBack()}
              className={classes.pageAboveTitle}
            />
          </Box>
        }
      >
        <form onSubmit={formMethods.handleSubmit(handleSubmit)} className={classes.disableAutofill}>
          <Grid container style={{columnGap: '16px', alignItems: 'stretch'}} wrap="nowrap">
            <Grid item lg={8} md={12} style={{position: 'relative', width: '100%'}}>
              <Paper elevation={4} style={{position: 'relative', height: '100%'}}>
                {isTablet && (
                  <Box
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      left: 0,
                      bottom: 0,
                      backgroundColor: '#FFFFFF',
                      opacity: showSummary ? 0.8 : 0,
                      zIndex: showSummary ? 1000 : 0
                    }}
                  />
                )}

                {isTablet && showSummary && (
                  <ClickAwayListener onClickAway={() => handleShowSummary(false)}>
                    <Box style={summaryStyle}>
                      <CloseButton onClose={() => handleShowSummary(false)} />
                      {summarySection}
                    </Box>
                  </ClickAwayListener>
                )}
                <Box
                  data-test-id="form-left"
                  style={{padding: isMobile ? '24px 18px' : '24px 36px', height: '100%'}}
                >
                  <Box
                    data-test-id="form-title"
                    style={{display: 'flex', justifyContent: 'space-between'}}
                  >
                    <SectionHeader
                      title={
                        isReadonly
                          ? channelType === 'Email'
                            ? messageDetails?.subject || ''
                            : t('createMessage.form.messageDetailsSMS')
                          : t('createMessage.form.newMessage')
                      }
                    />
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: isSmallMobile ? 'column' : 'row',
                        gap: '12px',
                        marginBottom: '12px',
                        visibility: isReadonly ? 'hidden' : 'visible'
                      }}
                    >
                      <Tooltip
                        placement="top"
                        title={
                          channelType === 'sms' && !userData?.mobileNumber
                            ? t('createMessage.form.sendTestMessage.noMobileNumber')
                            : channelType === 'Email' && !userData?.eMail
                              ? t('createMessage.form.sendTestMessage.noEmail')
                              : undefined
                        }
                      >
                        <span>
                          <LoadingButton
                            variant="outlined"
                            data-test-id="send-test-message-button"
                            loading={isLoadingUserData}
                            disabled={
                              isCreateMessageLoading ||
                              (channelType === 'Email'
                                ? formMethods.watch('message') && formMethods.watch('subject')
                                  ? !isEmpty(formMethods.formState.errors) || !userData?.eMail
                                  : !isEmpty(formMethods.formState.errors) ||
                                    isEmpty(formMethods.formState.touchedFields) ||
                                    !formMethods.formState.dirtyFields.message ||
                                    !formMethods.formState.dirtyFields.subject ||
                                    !userData?.eMail
                                : formMethods.watch('message') && userData?.mobileNumber
                                  ? false
                                  : !!formMethods.formState.errors.message ||
                                    formMethods.formState.touchedFields.message ||
                                    !formMethods.formState.dirtyFields.message ||
                                    !userData?.mobileNumber)
                            }
                            onClick={() => {
                              const subject = formMethods.getValues('subject')
                              const message = formMethods.getValues('message')
                              const channelType = formMethods.getValues('channelType')
                              const attachments = formMethods.getValues('attachments')
                              setIsTestMessage(true)
                              setShowConfirmationDialog({
                                subject,
                                message,
                                channelType,
                                attachments
                              })
                            }}
                            startIcon={
                              <Send
                                style={{
                                  visibility: isLoadingUserData || isReadonly ? 'hidden' : 'visible'
                                }}
                              />
                            }
                            className={classes.selectRecipentsButton}
                            style={{
                              width: isSmallMobile ? '100%' : 'auto !important',
                              alignSelf: isMobile ? 'center' : 'flex-end'
                            }}
                          >
                            {t('createMessage.form.sendTestMessage.button')}
                          </LoadingButton>
                        </span>
                      </Tooltip>
                      <Button
                        variant="outlined"
                        data-test-id="select-audiences-button"
                        onClick={() => setShowRecipients(true)}
                        startIcon={<PeopleAlt />}
                        className={classes.selectRecipentsButton}
                        style={{
                          width: isSmallMobile ? '100%' : 'auto !important',
                          alignSelf: isMobile ? 'center' : 'flex-end'
                        }}
                      >
                        {t('createMessage.form.selectAudiences')}
                      </Button>
                    </Box>
                  </Box>
                  <CreateMessageForm
                    formMethods={formMethods}
                    selectedAudiencesIds={selectedAudiencesIds}
                    setShowSummary={setShowSummary}
                    setSelectedAudiencesIds={setSelectedAudiencesIds}
                    messageInfo={messageInfo}
                    setMessageInfo={setMessageInfo}
                    showRecipients={showRecipients}
                    setShowRecipients={setShowRecipients}
                    files={files}
                    setFiles={setFiles}
                    isReadonly={isReadonly}
                  />
                </Box>
                <DiscardDialog
                  setShouldRedirect={setShouldRedirect}
                  shouldOpenDiscardDialog={shouldOpenDiscardDialog}
                  setShouldOpenDiscardDialog={setShouldOpenDiscardDialog}
                />
                <SendMessageConfirmationDialog
                  showDialog={showConfirmationDialog}
                  setShowDialog={setShowConfirmationDialog}
                  isLoading={isCreateMessageLoading}
                  handleSendMessage={handleSendMessage}
                  channelType={channelType}
                  totalCost={totalPrice}
                  isTestMessage={isTestMessage}
                />
              </Paper>
            </Grid>
            {!isTablet && summarySection}
          </Grid>
        </form>
      </Page>
    </Content>
  )
}
