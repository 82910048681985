import {TextField} from '@material-ui/core'
import {useMutation} from '@tanstack/react-query'
import {debounce} from 'lodash'
import React, {useEffect, useMemo} from 'react'
import {Controller, FieldValues} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {api} from '../../../App.store'
import {handleAllowedCharacters} from '../../../common/utils/handleAllowedCharacters'
import {UserInformationInputSkeleton} from '../Skeleton/UserInformationInputSkeleton'

import {UserInformationProps} from './types'
import {useStyles} from './UserInput.styles'

type UserEmailInputProps<T extends FieldValues> = UserInformationProps<T> & {
  canEditUserEmailPhone: boolean
  setShowMfaInput: (show: boolean) => void
}

export const UserEmailInput = <T extends FieldValues>({
  readonlyMode,
  formMethods,
  canEditUserEmailPhone,
  isLoading,
  setShowMfaInput,
  isQuickPreview,
  name
}: UserEmailInputProps<T>) => {
  const {t} = useTranslation()
  const errors = formMethods.formState.errors
  const classes = useStyles()
  const emailValue = formMethods.getValues().eMail
  // TODO: Unblock this once proper MFA flow is implemented
  const blocker = true
  const canUseAdLoginRequest = async (email: string) => {
    const response = await api.post('/identity/api/aad/canUseAdLogin', email, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    })
    return response.data
  }

  const {mutate} = useMutation(canUseAdLoginRequest, {
    onSuccess: (data) => {
      setShowMfaInput(!data.canUseAdLogin)
    }
  })

  const debouncedMutate = useMemo(() => debounce(mutate, 500), [emailValue])

  useEffect(() => {
    if (!isLoading && emailValue && !blocker) {
      debouncedMutate(emailValue)
    }
  }, [isLoading, emailValue])

  if (isLoading) {
    return <UserInformationInputSkeleton />
  }

  const hasError = !!errors.eMail?.message
  const localKey = `errorMessages.${errors.eMail?.message}`
  const helperText = hasError && t(localKey)

  return (
    <Controller
      name={name}
      control={formMethods.control}
      rules={{
        validate: (value) =>
          value.length === 0
            ? true
            : !value.includes('@')
              ? 'EMAIL_SIGN'
              : handleAllowedCharacters(value, 'EMAIL_FORMAT_NOT_ALLOWED')
      }}
      render={({field}) => (
        <TextField
          {...field}
          data-test-id={`userInformationForm-${field.name}`}
          className={isQuickPreview ? classes.quickPreviewTextField : classes.textField}
          fullWidth
          id="email"
          label={t('userInformationForm.email')}
          inputMode="email"
          autoComplete="email"
          margin="normal"
          error={hasError}
          helperText={helperText}
          style={{maxWidth: isQuickPreview ? undefined : 400}}
          disabled={!canEditUserEmailPhone}
          InputProps={{readOnly: readonlyMode, disableUnderline: readonlyMode || isQuickPreview}}
        />
      )}
    />
  )
}
