import {Site} from '@hconnect/apiclient'
import {useSites} from '@hconnect/common/hooks/useSites'
import {Typography} from '@hconnect/uikit'
import {Close} from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {Box, Chip, CircularProgress, TextField} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import clsx from 'clsx'
import React from 'react'
import {ControllerRenderProps} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {WidgetUserCreationFormData} from '../WidgetUserCreation.form'
import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'

type WidgetDestinationInputProps = {
  field: ControllerRenderProps<WidgetUserCreationFormData, `customerIds.${number}`>
  index: number
}
export const WidgetDestinationInput = ({field}: WidgetDestinationInputProps) => {
  const {t} = useTranslation()
  const {classes} = useWidgetUserCreationStyles()
  const {sites, isSitesLoading} = useSites(
    field?.value?.customerId,
    [],
    !!field?.value?.customerId.split('.')[1]
  )

  return (
    <Autocomplete
      multiple
      forcePopupIcon={true}
      disableClearable={true}
      popupIcon={
        isSitesLoading ? (
          <CircularProgress size={16} style={{color: '#016AD4'}} />
        ) : (
          <KeyboardArrowDownIcon style={{color: '#00274D'}} />
        )
      }
      className={clsx(classes.autocomplete, {
        [classes.autocompleteDisabled]: sites.length === 0
      })}
      loading={isSitesLoading}
      style={{marginTop: '12px'}}
      disabled={sites.length === 0}
      onChange={(_, value) => {
        field.onChange({...field.value, siteIds: value.map((site) => site.siteId)})
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          data-test-id={'select-destination'}
          label={t('widgetUserCreation.form.destination.label')}
          style={{color: '#54708C'}}
          focused={true}
          InputLabelProps={{shrink: true}}
        />
      )}
      getOptionLabel={(option) => `${option.siteId}-${option.siteName}-${option.street}`}
      options={sites}
      renderOption={(props, option) => (
        <Box
          {...props}
          component="li"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
          key={`${option.siteId}-${option.siteName}-${option.street}`}
        >
          <Typography>
            {t('widgetUserCreation.form.destination.site', {siteName: option.siteName})}
          </Typography>
          <Typography>
            {t('widgetUserCreation.form.destination.city', {city: option.city})}
          </Typography>
          <Typography>
            {t('widgetUserCreation.form.destination.street', {street: option.street})}
          </Typography>
        </Box>
      )}
      value={sites.filter(
        (site) => field.value.siteIds?.length > 0 && field.value.siteIds.includes(site.siteId)
      )}
      renderTags={(value: Site[], getTagProps) => {
        return (
          <Box style={{display: 'flex', flexDirection: 'column'}}>
            {value.map((option: Site, index: number) => (
              <Chip
                key={index}
                variant="outlined"
                className={classes.autocompleteTags}
                label={
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: '#FFFFFF',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '300px',
                      lineHeight: '16px'
                    }}
                  >
                    {option.siteName}
                  </Typography>
                }
                onDelete={(event) => getTagProps({index}).onDelete(event)}
                deleteIcon={<Close style={{color: '#FFFFFF', fontSize: '16px'}} />}
              />
            ))}
          </Box>
        )
      }}
    />
  )
}
