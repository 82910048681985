import {Typography} from '@hconnect/uikit'
import {ArrowBackIos} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router'

export const Header = () => {
  const {t} = useTranslation()
  const history = useHistory<{isUserList?: boolean; isUserCreation?: boolean}>()
  const shouldGoBack = history.location.state?.isUserList
  const shouldGoToSearch = history.location.state?.isUserCreation

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="start"
        width="100%"
      >
        {(shouldGoBack || shouldGoToSearch) && (
          <Button
            variant="text"
            style={{
              color: '#007837',
              fontSize: '14px',
              fontWeight: 500,
              marginLeft: '-8px',
              padding: '4px 16px'
            }}
            startIcon={<ArrowBackIos style={{color: '#007837', fontSize: '12px'}} />}
            onClick={() => (shouldGoBack ? history.goBack() : history.push('/widgetUserList'))}
          >
            {shouldGoBack ? t('quickPreview.goBack') : t('quickPreview.goToSearch')}
          </Button>
        )}
        <Typography style={{fontSize: '28px', fontWeight: 700, margin: '0 0 24px 0'}}>
          {t('quickPreview.userQuickViewHeader')}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginBottom="24px"
      >
        <Typography style={{fontWeight: 'bold'}}>{t('quickPreview.generalData')}</Typography>
      </Box>
    </Box>
  )
}
