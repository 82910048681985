import {Country} from '@hconnect/apiclient'
import {QueryFunction, useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../App.store'
import {RoleAssignment} from '../../modules/ManageUsers.selectors'

const countriesConfigurationFetcher: QueryFunction<Country[]> = async ({queryKey}) => {
  const [base, endpoint] = queryKey
  try {
    const response = await api.get<Country[]>(`/${base}/${endpoint}`)
    return response.data
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error('No cross-countries found!')
    }
    throw new Error('Error fetching user cross-country information')
  }
}

export const useUserCrossCountriesConfiguration = (
  loggedInUserRoles: RoleAssignment[] | null | undefined,
  includeCrossBorderUser?: boolean
) => {
  const query = useQuery<Country[], AxiosError>(
    ['configuration', 'roles/crosscountriesconfig'],
    countriesConfigurationFetcher,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!loggedInUserRoles && (includeCrossBorderUser ?? false)
    }
  )
  
  const isLoading = !!loggedInUserRoles && query.isLoading && (includeCrossBorderUser ?? false)

  return {
    ...query,
    isLoading
  }
}
