import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  dialogTitle: {
    fontWeight: 700,
    fontSize: '16px'
  },
  logOutButton: {
    color: '#29333D',
    fontWeight: 500,
    border: '1px solid #E8ECF0',
    borderRadius: '4px'
  },
  resumeButton: {
    backgroundColor: '#016AD4',
    color: '#FFFFFF',
    borderRadius: '4px',
    fontWeight: 300
  },
  dialogAddMsg: {
    display: 'block'
  }
}))
