import {Product} from '@hconnect/apiclient'
import {PhoneNumberTextField, Typography} from '@hconnect/uikit'
import {Box} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useStyles} from '../../../components/UserInformation/inputs/UserInput.styles'

import {WidgetInputsProps} from './WidgetInputs.types'

type WidgetMobileNumberInputProps = WidgetInputsProps & {
  selectedProducts?: Product[]
}
export const WidgetMobileNumberInput = ({
  formMethods,
  selectedProducts
}: WidgetMobileNumberInputProps) => {
  const {t} = useTranslation()
  const inputClasses = useStyles()

  const emailValue = formMethods.watch('email')

  const handleValidation = (value: string) => {
    if (!value) {
      if (selectedProducts?.find((product) => product === Product.OnSite)) {
        return t('widgetUserCreation.form.errors.onsiteMobileNumberRequired')
      }

      if (!emailValue) {
        return t('widgetUserCreation.form.errors.emailOrMobileNumber')
      }

      return true
    }

    return true
  }

  return (
    <Controller
      name="mobileNumber"
      control={formMethods.control}
      rules={{
        validate: (value) => handleValidation(value)
      }}
      render={({field}) => (
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          <PhoneNumberTextField
            inputRef={field.ref}
            {...field}
            data-test-id={`widgetUserCreation-${field.name}`}
            className={clsx({
              [inputClasses.quickPreviewTextField]: true,
              [inputClasses.quickPreviewTextFieldError]:
                formMethods.formState.touchedFields.mobileNumber &&
                !!formMethods.formState.errors.mobileNumber
            })}
            fullWidth
            id="mobileNumber"
            label={t('widgetUserCreation.form.mobileNumber')}
            onPhoneChange={(phoneNumber) => {
              formMethods.setValue('mobileNumber', phoneNumber, {
                shouldTouch: true
              })

              if (formMethods.formState.errors.mobileNumber?.type === 'validate') {
                formMethods.clearErrors('mobileNumber')
              }

              if (formMethods.formState.errors.email) {
                formMethods.trigger('email')
              }
            }}
            margin="normal"
            error={
              formMethods.formState.touchedFields.mobileNumber &&
              !!formMethods.formState.errors.mobileNumber
            }
            InputLabelProps={{shrink: true}}
            InputProps={{
              disableUnderline: true
            }}
            options={{nationalMode: true, allowDropdown: true}}
          />
          {formMethods.formState.touchedFields.mobileNumber &&
            !!formMethods.formState.errors.mobileNumber && (
              <Typography
                variant="caption"
                style={{color: 'rgba(218, 9, 1, 1)', padding: '0 8px', marginBottom: '4px'}}
              >
                {formMethods.formState.errors.mobileNumber?.message}
              </Typography>
            )}
        </Box>
      )}
    />
  )
}
