import {Typography} from '@hconnect/uikit'
import React from 'react'

type CompanyNameProps = {
  isCloneable: boolean
  customerName?: string | undefined
}
export const CompanyName = ({isCloneable, customerName}: CompanyNameProps) => {
  return (
    <Typography style={{opacity: isCloneable ? 1 : 0.5, textAlign: 'left'}}>
      {customerName}
    </Typography>
  )
}
