import {Product, User} from '@hconnect/apiclient'
import {BackendFieldError} from '@hconnect/authenticator/src/ErrorMap'
import {Button} from '@mui/material'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {UseFormReturn} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {api} from '../../../App.store'
import {RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {createUser} from '../../../modules/Users.actions'
import {WidgetUserCreationFormData} from '../WidgetUserCreation.form'

export type InviteUserPayload = {
  user_id: string
  product: Product
}

type WidgetUserRequestData = {
  createUser: Partial<User>
  assignUserRoles: Omit<RoleAssignment, 'userId'>[]
  inviteUser: Omit<InviteUserPayload, 'user_id'>[]
}

export const assignUserRole = async (payload: RoleAssignment) => {
  const response = await api.post<RoleAssignment>('/roles', payload)
  const {data: userRole} = response
  return userRole
}

export const updateUserRole = async (payload: RoleAssignment) => {
  const response = await api.put<RoleAssignment>(`/roles/${payload.id}`, payload)
  const {data: userRole} = response
  return userRole
}

export const deleteUserRole = async (payload: RoleAssignment) => {
  await api.delete(`/roles/${payload.id}`)
}

const inviteUser = async (payload: InviteUserPayload) => {
  return await api.post('/users/invite', payload)
}

const deleteUser = async (userId: string) => {
  await api.delete(`/users/${userId}`)
}

const createUserWithRoles = async (data: WidgetUserRequestData) => {
  let newUserId: string | undefined

  try {
    const response = await createUser(data.createUser)
    newUserId = response.user_id

    for (const userRole of data.assignUserRoles) {
      await assignUserRole({...userRole, userId: newUserId})
    }

    for (const invitationData of data.inviteUser) {
      await inviteUser({...invitationData, user_id: newUserId})
    }
    return newUserId
  } catch (e) {
    const error = e as AxiosError
    if (error.response?.status !== 403) {
      if (newUserId) {
        await deleteUser(newUserId)
      }
    }
    throw e
  }
}

export const useCreateWidgetUser = (
  formMethods: UseFormReturn<WidgetUserCreationFormData, object>
) => {
  const {t} = useTranslation()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const history = useHistory()

  const mutation = useMutation(createUserWithRoles, {
    onSuccess: (data) => {
      enqueueSnackbar(t('widgetUserCreation.form.successCreateMsg'), {
        anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
        variant: 'success',
        action: (key) => (
          <Button
            style={{color: '#FFFFFF', borderColor: 'transparent'}}
            onClick={() => closeSnackbar(key)}
          >
            Close
          </Button>
        )
      })
      history.push(`/manage/quick-preview/${data}`, {isUserCreation: true})
    },
    onError: (e) => {
      const error = e as AxiosError<{extendedData: {fieldErrors: BackendFieldError[]}}>
      if (error.response?.status === 403) {
        enqueueSnackbar(t('errorMessages.403_USER_ALREADY_EXISTS'), {
          anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
          variant: 'error',
          action: (key) => (
            <Button
              style={{color: '#FFFFFF', borderColor: 'transparent'}}
              onClick={() => closeSnackbar(key)}
            >
              Close
            </Button>
          )
        })
      } else {
        const fieldErrors = error?.response?.data?.extendedData?.fieldErrors
        if (fieldErrors && fieldErrors?.length > 0) {
          fieldErrors.forEach((fieldError) => {
            const input = fieldError.fieldName.toLowerCase() === 'email' ? 'email' : 'mobileNumber'
            formMethods.setError(input, {
              message:
                fieldError.validationErrorType.toLowerCase() === 'exists'
                  ? input === 'email'
                    ? t('errorMessages.USER_EMAIL_ALREADY_EXISTS')
                    : t('errorMessages.USER_MOBILE_NUMBER_ALREADY_EXISTS')
                  : input === 'email'
                    ? t('errorMessages.INVALID_EMAIL_FORMAT')
                    : t('errorMessages.INVALID_MOBILE_NUMBER')
            })
          })
        } else {
          enqueueSnackbar(t('errorboundary.error'), {
            anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
            variant: 'error',
            action: (key) => (
              <Button
                style={{color: '#FFFFFF', borderColor: 'transparent'}}
                onClick={() => closeSnackbar(key)}
              >
                Close
              </Button>
            )
          })
        }
      }
    }
  })
  const handleCreateUser = async (data: WidgetUserRequestData) => {
    mutation.mutate(data)
  }

  return {
    handleCreateUser,
    isLoading: mutation.isLoading,
    isUserCreated: mutation.isSuccess
  }
}
