import {FormControlLabel, Switch} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'
import {
  CustomerToggleBasedPermissionKeys,
  CustomerToggleBasedPermissions
} from '../WidgetUserCreation.types'

type WidgetPermissionInputProps = {
  permissionKey: string
  // selectedPermissions: CustomerToggleBasedPermissions
  // setSelectedPermissions: (selectedPermissions: CustomerToggleBasedPermissions) => void
  checked: boolean
  onChange: (checked: boolean) => void
  defaultValue?: boolean
  disabled?: boolean
}
export const WidgetPermissionInput = ({
  // selectedPermissions,
  // setSelectedPermissions,
  permissionKey,
  defaultValue,
  disabled,
  onChange,
  checked
}: WidgetPermissionInputProps) => {
  const {t} = useTranslation()
  const {classes} = useWidgetUserCreationStyles()
  return (
    <FormControlLabel
      value={permissionKey}
      disabled={disabled}
      control={
        <Switch
          color="primary"
          checked={checked}
          disabled={disabled}
          onChange={(_, checked) => onChange(checked)}
          data-test-id={`widgetUserCreation-${permissionKey}`}
          classes={{
            track: clsx({
              [classes.trackEnabled]: checked
            })
          }}
        />
      }
      label={t(`widgetUserCreation.form.actionBasedPermissions.${permissionKey}`)}
      labelPlacement="end"
      data-test-id={`widgetUserCreation-${permissionKey}-label`}
    />
  )
}
