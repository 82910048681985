import {Customer} from '@hconnect/apiclient'
import {ErrorOutline, DeleteOutline, Search} from '@mui/icons-material'
import {Box, Button, CircularProgress, TextField, TextFieldProps} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {UseFieldArrayRemove} from 'react-hook-form'

import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'

import {useStyles} from './WidgetCustomerSearchInput.styles'

type WidgetCustomerSearchInputProps = TextFieldProps & {
  handleSubmit: () => void
  label: string
  remove: UseFieldArrayRemove
  index: number
  isSingleItem: boolean
  customerResult?: Customer[]
  disabled?: boolean
  error?: boolean
  loading?: boolean
  disableActions?: {verify: boolean; remove: boolean}
  handleClear: () => void
}
export const WidgetCustomerSearchInput = ({
  handleSubmit,
  label,
  disabled,
  error,
  loading,
  customerResult,
  remove,
  index,
  disableActions,
  isSingleItem,
  handleClear,
  ...props
}: WidgetCustomerSearchInputProps) => {
  const {classes} = useStyles()
  const {classes: widgetClasses} = useWidgetUserCreationStyles()

  const handleActionButtons = () => {
    if (loading) {
      return <CircularProgress size={32} />
    }

    if (error) {
      return <ErrorOutline style={{color: 'rgba(218, 9, 1, 1)', fontSize: '24px'}} />
    }

    if (customerResult && customerResult.length > 0) {
      return (
        <Button
          disabled={disabled || disableActions?.remove}
          startIcon={<DeleteOutline style={{color: '#00374d', fontSize: '16px'}} />}
          className={widgetClasses.verifyButton}
          onClick={() => {
            isSingleItem ? handleClear() : remove(index)
          }}
        >
          Remove
        </Button>
      )
    }

    return (
      <Box style={{display: 'flex', gap: '10px'}}>
        <Button
          disabled={disabled || disableActions?.verify}
          startIcon={<Search style={{color: '#00374d', fontSize: '16px'}} />}
          className={widgetClasses.verifyButton}
          onClick={() => handleSubmit()}
        >
          Verify
        </Button>
        <Button
          disabled={disabled || disableActions?.remove}
          startIcon={<DeleteOutline style={{color: '#00374d', fontSize: '16px'}} />}
          className={widgetClasses.verifyButton}
          onClick={() => {
            isSingleItem ? handleClear() : remove(index)
          }}
        >
          Remove
        </Button>
      </Box>
    )
  }

  return (
    <Box style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
      <TextField
        {...props}
        disabled={disabled}
        label={label}
        value={
          customerResult && customerResult?.length > 0
            ? props.value + ` (${customerResult?.[0].customerName})`
            : props.value
        }
        variant="filled"
        style={{flex: 1}}
        className={clsx(classes.searchInput, {
          [classes.searchInputError]: error
        })}
        InputLabelProps={{shrink: true, className: classes.searchInputLabel}}
        InputProps={{
          readOnly: customerResult && customerResult?.length > 0
        }}
      />
      {handleActionButtons()}
    </Box>
  )
}
