import {Product} from '@hconnect/apiclient'
import {FormControlLabel, Switch} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'

type WidgetProductInputProps = {
  product: Product.Hub | Product.OnSite
  setSelectedProducts: (products: Product[]) => void
  selectedProducts?: Product[]
}
export const WidgetProductInput = ({
  product,
  selectedProducts,
  setSelectedProducts
}: WidgetProductInputProps) => {
  const {t} = useTranslation()
  const {classes} = useWidgetUserCreationStyles()

  return (
    <FormControlLabel
      value="top"
      control={
        <Switch
          color="primary"
          checked={!!selectedProducts?.find((selectedProduct) => selectedProduct === product)}
          onChange={() =>
            setSelectedProducts(
              selectedProducts?.find((selectedProduct) => selectedProduct === product)
                ? selectedProducts?.filter((selectedProduct) => selectedProduct !== product)
                : selectedProducts?.concat([product]) || [product]
            )
          }
          data-test-id={`invite-product-${product}`}
          classes={{
            track: clsx({
              [classes.trackEnabled]: !!selectedProducts?.find(
                (selectedProduct) => selectedProduct === product
              )
            })
          }}
        />
      }
      label={t(`widgetUserCreation.form.products.${product.toLowerCase()}`)}
      labelPlacement="end"
      data-test-id={`invite-product-${product}-label`}
    />
  )
}
