import {Typography} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {WidgetUserCreationForm} from './WidgetUserCreation.form'

export const WidgetUserCreation = () => {
  const {t} = useTranslation()

  return (
    <Box style={{width: '100%', padding: '14px'}}>
      <Typography style={{fontSize: '28px', fontWeight: 700, margin: '0 0 24px 0'}}>
        {t('widgetUserCreation.title')}
      </Typography>
      <Typography style={{fontSize: '18px', fontWeight: 600, marginBottom: '24px'}}>
        {t('widgetUserCreation.form.generalTitle')}
      </Typography>
      <WidgetUserCreationForm />
    </Box>
  )
}
