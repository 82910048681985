import {getBusinessLines} from '@hconnect/apiclient'
import {useQuery} from '@tanstack/react-query'

import {api} from '../../App.store'

export const useBusinessLines = (countryCode: string, enabled: boolean) => {
  return useQuery(
    ['businessLine', countryCode],
    async () => {
      const response = await getBusinessLines(api)(countryCode)
      return response.data
    },
    {enabled}
  )
}
