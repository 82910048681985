import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useIdleTimer} from 'react-idle-timer'

import {useStyles} from './AutoLogout.styles'
import {autoLogoutConfig} from './AutoLogoutConfig'
import soundFile from './logout-notification.mp3'

interface AutoLogoutProps {
  onLogout: () => void
}

export const AutoLogout = ({onLogout}: AutoLogoutProps) => {
  const {classes} = useStyles()
  const {t} = useTranslation()
  const [state, setState] = useState<'Active' | 'Idle' | 'Prompted'>('Active')
  const [remaining, setRemaining] = useState(autoLogoutConfig.logout)
  const [open, setOpen] = useState(false)

  const handleOnIdle = () => {
    setState('Idle')
    setOpen(false)
    onLogout()
  }

  const handleOnActive = () => {
    setState('Active')
    setOpen(false)
  }

  const handleOnPrompt = () => {
    setState('Prompted')
    setOpen(true)
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {getRemainingTime, activate} = useIdleTimer({
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onPrompt: handleOnPrompt,
    timeout: autoLogoutConfig.logout,
    promptBeforeIdle: autoLogoutConfig.promptBeforeIdle,
    throttle: autoLogoutConfig.throttle,
    syncTimers: autoLogoutConfig.syncTimers,
    crossTab: autoLogoutConfig.crossTab,
    leaderElection: autoLogoutConfig.leaderElection
  })

  const getRemainingTimeWrapper = () => getRemainingTime()
  const activateWrapper = () => activate()

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTimeWrapper() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (state === 'Prompted') {
      const audio = new Audio(soundFile)
      audio.play().catch((error) => {
        console.error('Error playing audio:', error)
      })
    }
  }, [state])

  const handleResumeWorking = () => {
    activateWrapper()
  }
  const timeTillPrompt = Math.max(remaining - autoLogoutConfig.promptBeforeIdle / 1000, 0)
  const minutes = String(Math.floor(remaining / 60)).padStart(2, '0')
  const seconds = String(remaining % 60).padStart(2, '0')

  if (timeTillPrompt !== 0) {
    return null
  }

  return (
    <Dialog open={open} onClose={handleResumeWorking} data-test-id="dialog-auto-logout">
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        {t('autoLogout.dialogTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('autoLogout.dialogMessage', {minutes, seconds})}
          <span className={classes.dialogAddMsg}>{t('autoLogout.dialogAdditionalMessage')}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          data-test-id="btn-logout"
          onClick={() => {
            onLogout()
          }}
          className={classes.logOutButton}
        >
          {t('autoLogout.dialogActionLogoutNow')}
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          data-test-id="btn-resume-working"
          onClick={handleResumeWorking}
          className={classes.resumeButton}
        >
          {t('autoLogout.dialogActionResumeWorking')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
