import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  textField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D1D9E0',
      borderRadius: '4px'
    },
    '& .MuiInputBase-input': {
      padding: '12px'
    }
  },
  searchButton: {
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: '#016AD4',
    fontWeight: 500,
    borderRadius: '4px',
    marginTop: '48px',
    '&:disabled': {
      backgroundColor: '#016AD4',
      opacity: 0.5,
      color: '#FFFFFF'
    }
  },
  createUserButton: {
    borderRadius: '4px',
    fontWeight: 500,
    color: '#29333D',
    borderColor: '#E8ECF0',
    '&:hover': {
      backgroundColor: '#f1f2f5',
      borderColor: '#E8ECF0'
    }
  },
  createUserButtonOutlined: {
    color: '#29333D',
    '&:hover': {
      backgroundColor: '#f1f2f5',
      borderColor: '#E8ECF0'
    }
  },
  createUserButtonFilled: {
    color: '#FFFFFF',
    backgroundColor: '#016AD4',
    '&:hover': {
      backgroundColor: '#016AD4',
      opacity: 0.5
    }
  }
}))
