import {makeStyles} from 'tss-react/mui'

export const useWidgetUserCreationStyles = makeStyles()(() => ({
  submitButton: {
    color: '#FFFFFF',
    backgroundColor: '#016AD4',
    borderRadius: '4px',
    padding: '12px 16px',
    '&:disabled': {
      backgroundColor: '#016AD4',
      opacity: 0.5,
      color: '#FFFFFF'
    },
    '&:hover': {
      backgroundColor: '#016AD4',
      opacity: 0.9
    }
  },
  progress: {
    color: '#FFFFFF'
  },
  discardButton: {
    color: '#016AD4',
    fontWeight: 500,
    letterSpacing: 0
  },
  trackEnabled: {
    backgroundColor: '#016AD4 !important'
  },
  selectDropdown: {
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    width: '100%',
    maxWidth: '100%',
    marginBottom: '12px',
    marginTop: '4px'
  },
  dropdownTextField: {
    '& .MuiInputBase-root': {
      marginLeft: '8px'
    },
    '& .MuiInputLabel-formControl': {
      left: '8px',
      color: '#00374D'
    }
  },
  autocomplete: {
    '.MuiIconButton-root': {
      outline: 'none',
      border: 'none',
      boxShadow: 'none'
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none'
    },
    '.MuiOutlinedInput-input': {
      padding: '0 16px',
      width: '100% !important'
    },
    '.MuiOutlinedInput-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    paddingTop: '10px',
    borderRadius: '4px',
    border: '1px solid #D1D9E0'
  },
  autocompleteDisabled: {
    opacity: 0.3
  },
  autocompleteTags: {
    backgroundColor: '#016AD4',
    color: '#FFFFFF',
    marginTop: '2px',
    maxWidth: 'min-content',
    '&:first-child': {
      marginTop: '8px'
    }
  },
  verifyButton: {
    border: '1px solid #E8ECF0',
    borderRadius: '6px',
    color: '#29333D',
    fontWeight: 500,
    padding: '16px 12px',
    lineHeight: '16px',
    '&:hover': {
      backgroundColor: 'rgba(232,236,240,0.5)',
      color: '#29333D',
      outline: 'none'
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      opacity: 0.3
    },
    '&.MuiButton-outlinedPrimary:focus': {
      border: '1px solid #E8ECF0',
      color: '#29333D',
      backgroundColor: 'transparent'
    }
  }
}))
