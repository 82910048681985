import {useTranslation} from '@hconnect/uikit'
import {useEffect} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {useParams} from 'react-router'
import {useHistory} from 'react-router-dom'

import {OrderChangeConfigurationDetails} from './components/OrderChangeConfigurationDetails'
import {useOrderChangeConfigsEditMutation} from './hooks/useOrderChangeConfigsMutations'
import {useOrderChangeConfigsQuery} from './hooks/useOrderChangeConfigsQuery'
import {useOrderChangeConfigFieldsQuery} from './hooks/useOrderChangeConfigurationOptions'
import {OrderChangeConfig} from './types/OrderChangeConfig'

export const OrderChangeConfigurationEdit = () => {
  const {configId} = useParams<{
    configId?: string
  }>()

  const {t} = useTranslation()
  const history = useHistory()
  const orderChangeConfigsMutation = useOrderChangeConfigsEditMutation()

  const orderChangeConfigQueryInfo = useOrderChangeConfigsQuery()
  const orderChangeConfigFieldsQueryInfo = useOrderChangeConfigFieldsQuery()

  const methods = useForm<OrderChangeConfig>({
    defaultValues: {} as OrderChangeConfig,
    mode: 'onChange'
  })

  useEffect(() => {
    if (configId && orderChangeConfigQueryInfo.data) {
      const config = orderChangeConfigQueryInfo.data.find((config) => config.id === configId)
      if (config) {
        methods.reset(config)
      }
    }
  }, [configId, orderChangeConfigQueryInfo.data, methods])

  const handleSaveButtonClick = async (data: OrderChangeConfig) => {
    try {
      await orderChangeConfigsMutation.mutateAsync(data)
    } finally {
      history.goBack()
    }
  }

  if (orderChangeConfigFieldsQueryInfo.isLoading || !orderChangeConfigFieldsQueryInfo.data) {
    return <div>{t('configurations.hconnect.orderChange.configurationDetails.loading')}</div>
  }

  return (
    <FormProvider {...methods}>
      <OrderChangeConfigurationDetails
        mode="edit"
        configId={configId}
        onSaveButtonClick={methods.handleSubmit(handleSaveButtonClick)}
        configOptions={orderChangeConfigFieldsQueryInfo.data}
        isSubmitting={orderChangeConfigsMutation.isLoading}
      />
    </FormProvider>
  )
}
