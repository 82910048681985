import {useTranslation} from '@hconnect/uikit'
import {useForm, FormProvider} from 'react-hook-form'
import {useHistory} from 'react-router-dom'

import {OrderChangeConfigurationDetails} from './components/OrderChangeConfigurationDetails'
import {useOrderChangeConfigsCreateMutation} from './hooks/useOrderChangeConfigsMutations'
import {useOrderChangeConfigFieldsQuery} from './hooks/useOrderChangeConfigurationOptions'
import {OrderChangeCreateConfig} from './types/OrderChangeConfig'

const emptyFormState: OrderChangeCreateConfig = {
  countryCode: '',
  businessLine: '',
  orgUnitId: '',
  dispatchGroup: '',
  soldTo: '',
  fieldSettings: []
}

export const OrderChangeConfigurationCreate = () => {
  const history = useHistory()
  const orderChangeConfigsMutation = useOrderChangeConfigsCreateMutation()
  const {t} = useTranslation()

  const methods = useForm<OrderChangeCreateConfig>({
    defaultValues: emptyFormState,
    mode: 'onChange'
  })

  const handleCreateButtonClick = async (data: OrderChangeCreateConfig) => {
    try {
      await orderChangeConfigsMutation.mutateAsync(data)
    } finally {
      history.goBack()
    }
  }

  const orderChangeConfigFieldsQueryInfo = useOrderChangeConfigFieldsQuery()

  if (orderChangeConfigFieldsQueryInfo.isLoading || !orderChangeConfigFieldsQueryInfo.data) {
    return <div>{t('configurations.hconnect.orderChange.configurationDetails.loading')}</div>
  }

  return (
    <FormProvider {...methods}>
      <OrderChangeConfigurationDetails
        mode="create"
        onSaveButtonClick={methods.handleSubmit(handleCreateButtonClick)}
        configOptions={orderChangeConfigFieldsQueryInfo.data}
        isSubmitting={orderChangeConfigsMutation.isLoading}
      />
    </FormProvider>
  )
}
