interface AutoLogoutConfig {
  logout: number
  promptBeforeIdle: number
  throttle: number
  syncTimers: number
  crossTab: boolean
  leaderElection: boolean
  roles: string[]
}

export const autoLogoutConfig: AutoLogoutConfig = {
  logout: process.env.REACT_APP_STAGE === 'prod' ? 1000 * 60 * 30 : 1000 * 60 * 480, // PROD 30 minutes and other environment 8 hour (total inactivity time to logout)
  promptBeforeIdle: 1000 * 60 * 10, // 10 minutes before logout to emit prompt
  throttle: 500,
  syncTimers: 200,
  crossTab: true,
  leaderElection: true,
  roles: [
    'GLOBAL_ADMIN',
    'DATALINK_ADMIN',
    'SUPER_ADMIN',
    'BUSINESS_OWNER',
    'CUSTOMER_ADMIN',
    'SALES_AGENT',
    'SERVICE_ACCOUNT',
    'EXPORT_ADMIN',
    'FEATURES_ADMIN'
  ]
}