import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles<{isMobile: boolean}>()((theme, {isMobile}) => ({
  backButton: {
    flex: '1 0 100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },
  pageNav: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.6,
    marginLeft: '-12px',
    paddingBottom: '6px'
  },
  pageTitle: {
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: 600,
    marginLeft: '8px',
    marginBottom: '16px'
  },
  pageAboveTitle: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: '24px'
    }
  },
  gridContainer: {
    marginBottom: '20px'
  },
  gridItem: {
    position: 'relative',
    width: '100%'
  },
  paper: {
    position: 'relative',
    padding: '16px'
  },
  tabletBox: {
    position: 'absolute',
    margin: 0,
    backgroundColor: '#FFFFFF'
  },
  contentBox: {
    padding: isMobile ? '24px 18px' : '24px 36px'
  },
  countrySpecificationBox: {
    marginBottom: '16px'
  },
  countrySpecificationText: {
    fontSize: '18px',
    fontWeight: 600
  },
  saveButtonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px'
  }
}))
