import {Typography} from '@hconnect/uikit'
import {TextField} from '@material-ui/core'
import {Box} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {handleNoSpecialCharacters} from '../../../common/utils/handleNoSpecialCharacters'
import {useStyles} from '../../../components/UserInformation/inputs/UserInput.styles'

import {WidgetInputsProps} from './WidgetInputs.types'

export const WidgetNameInput = ({formMethods}: WidgetInputsProps) => {
  const {t} = useTranslation()
  const inputClasses = useStyles()
  return (
    <Controller
      name="name"
      control={formMethods.control}
      rules={{
        required: {
          value: true,
          message: t('widgetUserCreation.form.errors.required', {
            fieldName: t('widgetUserCreation.form.name')
          })
        },
        validate: (value) => handleNoSpecialCharacters(value, t)
      }}
      render={({field}) => (
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          <TextField
            {...field}
            data-test-id={`widgetUserCreation-${field.name}`}
            className={clsx({
              [inputClasses.quickPreviewTextField]: true,
              [inputClasses.quickPreviewTextFieldError]:
                formMethods.formState.touchedFields.name && !!formMethods.formState.errors.name
            })}
            fullWidth
            label={t('widgetUserCreation.form.name')}
            autoComplete="name"
            margin="normal"
            error={formMethods.formState.touchedFields.name && !!formMethods.formState.errors.name}
            InputProps={{disableUnderline: true}}
            InputLabelProps={{shrink: true}}
          />
          {formMethods.formState.touchedFields.name && !!formMethods.formState.errors.name && (
            <Typography
              variant="caption"
              style={{color: 'rgba(218, 9, 1, 1)', padding: '0 8px', marginBottom: '4px'}}
            >
              {formMethods.formState.errors.name?.message}
            </Typography>
          )}
        </Box>
      )}
    />
  )
}
