export enum CustomerToggleBasedPermissionKeys {
  canSeeOrderAndDeliveries = 'canSeeOrderAndDeliveries',
  canCreateAndChangeOrders = 'canCreateAndChangeOrders',
  canSeeInvoices = 'canSeeInvoices'
}

export type CustomerToggleBasedPermissions = {
  [CustomerToggleBasedPermissionKeys.canSeeOrderAndDeliveries]?: boolean
  [CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders]?: boolean
  [CustomerToggleBasedPermissionKeys.canSeeInvoices]?: boolean
}

export type DataScopeOption = {
  label: string
  value: string
}
