import {Typography} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Controller, UseFieldArrayRemove} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useCustomerByNumber} from '../../../hooks/useCustomer'
import {RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {DataScopeOption} from '../WidgetUserCreation.types'

import {WidgetCustomerSearchInput} from './WidgetCustomerSearchInput'
import {WidgetDestinationInput} from './WidgetDestinationInput'
import {WidgetInputsProps} from './WidgetInputs.types'

type WidgetCustomerInputProps = WidgetInputsProps & {
  index: number
  remove: UseFieldArrayRemove
  selectedOrgUnit?: DataScopeOption
  cscData?: RoleAssignment[]
  roles?: RoleAssignment[] | null
}
export const WidgetCustomerInput = ({
  formMethods,
  selectedOrgUnit,
  cscData,
  roles,
  index,
  remove
}: WidgetCustomerInputProps) => {
  const {t} = useTranslation()
  const selectedCountry = formMethods.watch('country')
  const selectedBusinessLine = formMethods.watch('businessLine')
  const customerIds = formMethods.watch('customerIds')
  const countries = roles
    ?.map((role) => role.dataScope?.['countryId'] as string[])
    .reduce((a, b) => a?.concat(b), [])
    .filter((value) => !!value)
    .filter((value, index, array) => array.indexOf(value) === index)
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState<string | undefined>()

  const {
    data: customersData,
    isInitialLoading: isLoadingCustomersData,
    isError: isCustomersDataError
  } = useCustomerByNumber(
    customerIds?.[index]?.customerId?.split('.')[1] || selectedCustomerNumber || '',
    countries && countries.length === 1
      ? countries[0]
      : cscData &&
          cscData
            ?.map((data) => data.dataScope?.['countryId'])
            ?.filter((item, index, arr) => arr.findIndex((el) => el === item) === index).length > 1
        ? selectedCountry
        : (cscData?.[0].dataScope?.['countryId'] as string | undefined) || '',
    selectedOrgUnit?.value || '',
    cscData && cscData?.length > 1
      ? selectedBusinessLine
      : (cscData?.[0].dataScope?.['businessLine'] as string) || '',
    !!selectedCustomerNumber ||
      (!!customerIds?.[index]?.customerId?.split('.')[1] &&
        formMethods.formState.defaultValues?.customerIds &&
        formMethods.formState.defaultValues?.customerIds?.filter((value) => !!value?.customerId)
          ?.length > 0)
  )

  useEffect(() => {
    if (customersData && customersData?.length > 0) {
      formMethods.setValue(`customerIds.${index}`, {
        customerId: customersData[0].customerId,
        siteIds: formMethods.watch('customerIds')[index]?.siteIds
      })
      formMethods.trigger(`customerIds.${index}`)
    }

    if (customersData && customersData?.length === 0) {
      formMethods.setError(`customerIds.${index}`, {
        message: t('widgetUserCreation.form.errors.customerNoResults')
      })
    }

    if (isCustomersDataError) {
      formMethods.setError(`customerIds.${index}`, {
        message: t('widgetUserCreation.form.errors.customerDataError')
      })
    }
  }, [customersData, isCustomersDataError])

  return (
    <Controller
      name={`customerIds.${index}`}
      control={formMethods.control}
      rules={{
        validate: (value) => {
          if (!value.customerId.split('.')?.[1]) {
            return t('widgetUserCreation.form.errors.required', {
              fieldName: t('widgetUserCreation.form.customerId')
            })
          } else return true
        }
      }}
      render={({field}) => (
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          <WidgetCustomerSearchInput
            data-test-id={`userList-details-customerId-field-${index}`}
            remove={remove}
            index={index}
            isSingleItem={formMethods.watch('customerIds')?.length === 1}
            handleClear={() => {
              setSelectedCustomerNumber(undefined)
              formMethods.resetField(`customerIds.${index}`, {
                defaultValue: {customerId: '', siteIds: []}
              })
            }}
            handleSubmit={() => {
              const areValuesDuplicated = formMethods
                .watch('customerIds')
                .map((data) => data.customerId.split('.')?.[1])
                .includes(field.value.customerId)
              if (areValuesDuplicated) {
                formMethods.setError(`customerIds.${index}`, {
                  message: t('widgetUserCreation.form.errors.duplicatedValues')
                })
              } else {
                setSelectedCustomerNumber(customerIds?.[index]?.customerId)
              }
              if (customerIds?.[index]?.customerId === field.value.customerId) {
                if (isCustomersDataError) {
                  formMethods.setError(`customerIds.${index}`, {
                    message: t('widgetUserCreation.form.errors.customerDataError')
                  })
                }
                if (customersData?.length === 0) {
                  formMethods.setError(`customerIds.${index}`, {
                    message: t('widgetUserCreation.form.errors.customerNoResults')
                  })
                }
              }
            }}
            onChange={(event) => {
              formMethods.clearErrors(`customerIds.${index}`)
              field.onChange({customerId: event.currentTarget.value, siteIds: []})
            }}
            value={field.value.customerId.split('.')?.[1] || field.value.customerId}
            label={t('widgetUserCreation.form.customerId')}
            disabled={!formMethods.watch('orgUnit') && !selectedOrgUnit}
            disableActions={{
              verify: !field.value.customerId,
              remove: false
            }}
            error={!!formMethods.formState.errors.customerIds?.[index]}
            loading={isLoadingCustomersData}
            customerResult={
              formMethods.watch('customerIds')?.[index]?.customerId ? customersData : []
            }
          />
          {formMethods.formState.errors.customerIds?.[index] && (
            <Typography
              variant="caption"
              style={{color: 'rgba(218, 9, 1, 1)', padding: '8px 8px', marginBottom: '4px'}}
            >
              {formMethods.formState.errors.customerIds?.[index]?.message}
            </Typography>
          )}
          <WidgetDestinationInput field={field} index={index} />
        </Box>
      )}
    />
  )
}
