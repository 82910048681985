import {trackEvent} from '@hconnect/common/logging/Analytics'
import {QueryFunction, useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {api} from '../../../../../App.store'
import {OrderChangeConfig} from '../types/OrderChangeConfig'

import {OrderChangeConfigQueryKey, ProductConfigsQueryKey} from './queryKeys'

const orderChangeConfigurationFetcher: QueryFunction<OrderChangeConfig[]> = async ({queryKey}) => {
  // TODO: once API is ready
  // const [base, endpoint] = queryKey

  const response = await api.get<OrderChangeConfig[]>('/product-configs/order-change-settings')
  return response.data
}

export const useOrderChangeConfigsQuery = () => {
  return useQuery<OrderChangeConfig[], AxiosError>(
    [ProductConfigsQueryKey, OrderChangeConfigQueryKey],
    orderChangeConfigurationFetcher,
    {
      onError: (error) => {
        console.error(error)
        trackEvent('adminConsoleError', {
          product: 'adminConsole',
          date: new Date().toISOString(),
          errorCode: error.response?.status,
          component: 'useOrderChangeConfigsQuery',
          endpoint: '/product-configs/order-change-settings'
        })
      }
    }
  )
}
