import {
  ApiProduct,
  getApiProducts,
  getArea,
  getAreas,
  getBusinessLines,
  getCountries,
  getOrgUnits,
  StringParams
} from '@hconnect/apiclient'

import {api} from '../App.store'
import {DropdownOption} from '../common/DropdownOption'
import {DataScopeConfiguration} from '../common/hooks/useRolesConfiguration'

import {RoleType} from './ManageUsers.selectors'

export const TEXT_DATA_SCOPE_TYPE = 'text'
export const DYNAMIC_SELECT_DATA_SCOPE_TYPE = 'dynamic-select'
export const LOOKUP_DATA_SCOPE_TYPE = 'lookup'
export const HIDE_DATA_SCOPE_TYPE = 'hide'
export const READONLY_DATA_SCOPE_TYPE = 'readonly'
export const DELETEONLY_DATA_SCOPE_TYPE = 'deleteonly'

const setApiProductsByRoleType = async (roleType?: RoleType): Promise<DropdownOption[]> => {
  const query = generateQueryToRetrieveApiProductsByRoleType(roleType)
  if (query) {
    const {data: filteredApiProducts} = await getApiProducts(api)(query)

    return filteredApiProducts.apiProduct.map(({displayName}: ApiProduct) => ({
      key: displayName,
      label: displayName
    }))
  }

  return Promise.resolve([])
}

const generateQueryToRetrieveApiProductsByRoleType = (roleType?: RoleType): StringParams | null => {
  const roleTypeMapping: {[key in RoleType]?: StringParams} = {
    DATALINK_DEVELOPER: {attributename: 'integration', attributevalue: 'Ecs', expand: 'true'},
    DATALINK_INTEGRATION_PARTNER: {
      attributename: 'integration',
      attributevalue: 'IntegrationPartner',
      expand: 'true'
    },
    DATALINK_PENDING_DEVELOPER: {
      attributename: 'integration',
      attributevalue: 'Sample',
      expand: 'true'
    }
  }

  return roleType ? roleTypeMapping[roleType] || null : null
}

interface TextDataScopeInput {
  type: typeof TEXT_DATA_SCOPE_TYPE
}

export interface DynamicSelectDataScopeInput {
  type: typeof DYNAMIC_SELECT_DATA_SCOPE_TYPE
  dependsOn: string[]
  orderWeight: number
  fetchValues: (dependencies: Array<any>) => Promise<Array<DropdownOption>>
}

export interface LookupDataScopeInput {
  type: typeof LOOKUP_DATA_SCOPE_TYPE
  dependsOn: string[]
}

interface HideDataScopeInput {
  type: typeof HIDE_DATA_SCOPE_TYPE
}

interface ReadonlyDataScopeInput {
  type: typeof READONLY_DATA_SCOPE_TYPE
}

interface DeleteonlyDataScopeInput {
  type: typeof DELETEONLY_DATA_SCOPE_TYPE
}

export type DataScopeInput =
  | TextDataScopeInput
  | LookupDataScopeInput
  | HideDataScopeInput
  | DynamicSelectDataScopeInput
  | ReadonlyDataScopeInput
  | DeleteonlyDataScopeInput

export const getDataScopeConfiguration = (
  dataScope: string,
  isUserAdminPilot?: boolean,
  roleType?: RoleType
): DataScopeInput => {
  const configurations: {[dataScopeName: string]: DataScopeInput} = {
    areaId: {
      type: DYNAMIC_SELECT_DATA_SCOPE_TYPE,
      dependsOn: [],
      orderWeight: 1,
      fetchValues: async () => {
        const response = await getAreas(api)()

        return response.data
          .map((area) => ({
            key: area.areaCode,
            label: area.areaCode
          }))
          .filter((item, index, self) => index === self.findIndex((t) => t.key === item.key))
          .sort((a, b) => a.label.localeCompare(b.label))
      }
    },
    countryId: {
      type: DYNAMIC_SELECT_DATA_SCOPE_TYPE,
      dependsOn: ['areaId'],
      orderWeight: 1,
      fetchValues: async ([areaId]) => {
        let countries: any[]
        if (!areaId) {
          const response = await getCountries(api)()
          countries = response.data
        } else {
          const response = await getArea(api)(areaId)
          countries = response.data.countries
        }

        return countries
          .map((country) => ({
            key: country.countryCode,
            label: country.countryCode
          }))
          .filter((item, index, self) => index === self.findIndex((t) => t.key === item.key))
          .sort((a, b) => a.label.localeCompare(b.label))
      }
    },
    orgUnitId: {
      type: DYNAMIC_SELECT_DATA_SCOPE_TYPE,
      dependsOn: ['countryId', 'businessLine'],
      orderWeight: 3,
      fetchValues: async ([countryId, businessLine]) => {
        if (!countryId && !isUserAdminPilot) {
          return []
        }

        const response = await getOrgUnits(api)(countryId as string, {businessLine})
        return response.data
          .map((orgUnit) => ({
            key: orgUnit.orgUnitId,
            label: orgUnit.orgUnitName
          }))
          .filter((item, index, self) => index === self.findIndex((t) => t.key === item.key))
          .sort((a, b) => a.label.localeCompare(b.label))
      }
    },
    businessLine: {
      type: DYNAMIC_SELECT_DATA_SCOPE_TYPE,
      dependsOn: ['countryId'],
      orderWeight: 2,
      fetchValues: async ([countryId]) => {
        if (!countryId && !isUserAdminPilot) {
          return []
        }

        const response = await getBusinessLines(api)(countryId as string)

        return response.data
          .map((businessLine) => ({
            key: businessLine.businessLineCode,
            label: businessLine.businessLineName
          }))
          .filter((item, index, self) => index === self.findIndex((t) => t.key === item.key))
          .sort((a, b) => a.label.localeCompare(b.label))
      }
    },
    apiProducts: {
      type: DYNAMIC_SELECT_DATA_SCOPE_TYPE,
      dependsOn: [],
      orderWeight: 2,
      fetchValues: async () => setApiProductsByRoleType(roleType)
    },
    projectId: {type: HIDE_DATA_SCOPE_TYPE},
    projectIds: {type: HIDE_DATA_SCOPE_TYPE},
    // siteId, siteIds, orderId and orderIds dataScopes are handled in CustomersAndSites component
    // payerId, payerIds are not handled in the UI at all
    siteId: {type: HIDE_DATA_SCOPE_TYPE},
    siteIds: {type: HIDE_DATA_SCOPE_TYPE},
    orderId: {type: DELETEONLY_DATA_SCOPE_TYPE},
    orderIds: {type: DELETEONLY_DATA_SCOPE_TYPE},
    contractIds: {type: HIDE_DATA_SCOPE_TYPE},
    customerIds: {
      type: LOOKUP_DATA_SCOPE_TYPE,
      dependsOn: ['countryId', 'businessLine', 'orgUnitId']
    },
    payerId: {
      type: LOOKUP_DATA_SCOPE_TYPE,
      dependsOn: ['countryId', 'businessLine', 'orgUnitId']
    },
    payerIds: {
      type: LOOKUP_DATA_SCOPE_TYPE,
      dependsOn: ['countryId', 'businessLine', 'orgUnitId']
    },
    plantIds: {
      type: LOOKUP_DATA_SCOPE_TYPE,
      dependsOn: ['countryId']
    },
    commentCategories: {
      type: LOOKUP_DATA_SCOPE_TYPE,
      dependsOn: []
    },
    haulierIds: {
      type: LOOKUP_DATA_SCOPE_TYPE,
      dependsOn: ['countryId', 'businessLine', 'orgUnitId']
    },
    webhookSubscriptions: {type: HIDE_DATA_SCOPE_TYPE}
  }
  return configurations[dataScope] || {type: TEXT_DATA_SCOPE_TYPE}
}

export const getDataScopeWeight = (dataScope: string): number => {
  const config = getDataScopeConfiguration(dataScope)
  if (config.type !== DYNAMIC_SELECT_DATA_SCOPE_TYPE) {
    return Infinity
  }
  return config.orderWeight
}

// Client-site workaround until we get orderWeight from the roleConfiguration (backend)
export const sortDataScopes = (dsA: DataScopeConfiguration, dsB: DataScopeConfiguration) =>
  getDataScopeWeight(dsA.dataScope) - getDataScopeWeight(dsB.dataScope)
