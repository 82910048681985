import {Typography, useTranslation} from '@hconnect/uikit'
import {Box, MenuItem, TextField, SelectChangeEvent} from '@mui/material'
import {useFormContext, Controller} from 'react-hook-form'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import {OrderChangeConfig} from '../types/OrderChangeConfig'

import {useStyles} from './OrderChangeFormFields.styles'

type Props = {
  handleCountryChange: (event: SelectChangeEvent<string>) => void
  countries: string[]
  disabled: boolean
}

const DataScopeNames = ['businessLine', 'orgUnitId', 'dispatchGroup', 'soldTo']

export const OrderChangeFormFields = ({handleCountryChange, countries, disabled}: Props) => {
  const {t} = useTranslation()
  const {control} = useFormContext<OrderChangeConfig>()
  const {classes} = useStyles()

  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <Controller
        name="countryCode"
        control={control}
        rules={{
          required: t('configurations.hconnect.orderChange.configurationDetails.requiredField', {
            fieldName: 'countryCode'
          })
        }}
        render={({field: controllerField, fieldState}) => (
          <Box>
            <TextField
              {...controllerField}
              onChange={(event) => {
                const selectEvent = event as SelectChangeEvent<string>
                controllerField.onChange(selectEvent)
                handleCountryChange(selectEvent)
              }}
              fullWidth
              select
              SelectProps={{
                IconComponent: KeyboardArrowDownIcon
              }}
              label={t('configurations.hconnect.orderChange.configurationDetails.countryCodeLabel')}
              value={controllerField.value}
              error={!!fieldState.error}
              disabled={disabled}
              InputProps={{className: classes.select, 'aria-label': 'Country Code'}}
              InputLabelProps={{shrink: true, className: classes.selectLabel}}
              variant="outlined"
              data-test-id="order-change-configurations-country-code"
            >
              <MenuItem value="" disabled className={classes.selectMenu}>
                {t('configurations.hconnect.orderChange.configurationDetails.countryCodeLabel')}
              </MenuItem>
              {countries.map((country) => (
                <MenuItem key={country} value={country} className={classes.selectMenu}>
                  {country}
                </MenuItem>
              ))}
            </TextField>
            {fieldState.error && (
              <Typography
                className={classes.errorText}
                data-test-id={`order-change-configurations-validation-${controllerField.name}`}
              >
                {fieldState.error.message}
              </Typography>
            )}
          </Box>
        )}
      />
      {DataScopeNames.map((field) => (
        <Controller
          key={field}
          name={field as keyof OrderChangeConfig}
          control={control}
          rules={{
            required:
              field === 'businessLine' || field === 'orgUnitId'
                ? t('configurations.hconnect.orderChange.configurationDetails.requiredField', {
                    fieldName: t(
                      `configurations.hconnect.orderChange.configurationDetails.${field}Label`
                    )
                  })
                : false
          }}
          render={({field: controllerField, fieldState}) => (
            <Box>
              <TextField
                {...controllerField}
                fullWidth
                label={t(`configurations.hconnect.orderChange.configurationDetails.${field}Label`)}
                type="text"
                variant="standard"
                className={`${classes.textField} ${fieldState.error ? classes.errorField : ''}`}
                InputProps={{disableUnderline: true}}
                InputLabelProps={{className: classes.inputLabel, shrink: true}}
                disabled={disabled}
                error={!!fieldState.error}
                focused={true}
              />
              {fieldState.error && (
                <Typography
                  className={classes.errorText}
                  data-test-id={`order-change-configurations-validation-${controllerField.name}`}
                >
                  {fieldState.error.message}
                </Typography>
              )}
            </Box>
          )}
        />
      ))}
    </Box>
  )
}
